.paymentCard {
    padding: 0;
    display: block;
    .checkBoxCheck {
        top: 1.25rem;
        right: 1.25rem;
        left: auto;
    }
    .paymentCardBox {
        background-color: var(--gray-600);
        border: 1px solid var(--gray-600);
        border-radius: 0.375rem;
        padding: 1.25rem;
        display: flex;
    }
    input:checked ~ .paymentCardBox {
        background-color: var(--pastel-100);
        border: 1px solid rgba(241, 88, 69, 0.4);
    }
     input[type="radio"] {
        opacity: 0;
    }
}

.payCard {
    max-width: 360px;
    float: right;
    border-top: 8px solid #F15845;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
}
.modalDialogxl {
    .modal-content {
        padding: 0px 15px;
    }
}
.StripeElement{
    border: 1px solid var(--gray-400);
    font-size: 1rem;
    border-radius: 6px;
    padding: 0.98rem 0.5rem 0.98rem 1rem;
    &.StripeElement--empty{
        border: 1px solid var(--gray-400);
    }
    &.focused{
        border: 1px solid var(--primary-200);
    }
}