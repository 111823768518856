.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -36px 0 0 -36px;
    cursor: pointer;

    .play-bg {
        @include transition;
    }

    &:hover {

        .play-bg {
            transform: scale(1.2);
            @include transition;
        }

    }

    .play-btn {
        position: absolute;
        left: 0;
        z-index: 99;
    }
}

.zoomIcon {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
}

.videoPreview {
    position: relative;
    margin: 2rem;
    // margin: 2.5rem
    br {
        display: none;
    }
}