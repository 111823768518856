.arrow {
    border: solid var(--gray-200);
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 0.265rem;

    &.gray-100 {
        border-color: var(--gray-100);
        padding: 3px;
    }

    &.gray-200 {
        border-color: var(--gray-200);
        padding: 4px;
    }



    &.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    &.left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    &.up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    &.down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}