.pageHeader {
    // padding: 1.22rem 0;
    background: var(--base-100);
    box-shadow: 0px 12px 24px -10px rgba(0, 0, 0, 0.06);
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--gray-400);
    position: relative;
    z-index: 2;
    // .container-fluid {
    //     padding-right: 4rem;
    // }
    display: flex;
    align-items: center;
    min-height: 5rem;
}


/*serach*/

.myInputSearch {
    width: 18.5rem;
    max-width: initial;
    &.lg {
        input {
            padding: 0.75rem 1rem;
            font-size: 1rem;

            &::placeholder {
                font-size: 1rem;
            }
        }
    }

    input {

        padding: .5625rem .75rem;
        font-size: 0.75rem;
        line-height: 1.25rem;

        &::placeholder {
            font-size: 0.75rem;
            line-height: 1.25rem;

        }
    }

    svg {
        position: absolute;
        right: 0.625rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
    }
}

/*hamberger*/

.hamberger {
    position: relative;

    .dot {
        position: absolute;
        height: 0.375rem;
        width: 0.375rem;
        border-radius: 50%;
        top: -3px;
        right: -3px;
        background-color: var(--primary-100);
    }
}