.accordion-item {
    border: 0;
    position: relative;
  
    .accordion-header {
      .accordion-button {
        border-radius: 0.375rem;
        overflow: hidden;
        padding: 0 1.5rem 0 0;
        background-color: var(--base-100);
        box-shadow: none;
        border-bottom: transparent;
        cursor: pointer;
  
        &:not(.collapsed) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          overflow: hidden;
          border-bottom: 0.0625rem solid var(--gray-500);
          &:after {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
            // top: 0.1875rem;
          }
        }
  
        &:after {
          border: solid var(--gray-300);
          border-width: 0 0.0625rem 0.0625rem 0 !important;
          display: inline-block;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          position: relative;
          height: 0.625rem;
          width: 0.625rem;
          background-image: none;
          top: -0.1875rem;
        }
  
        &:focus {
          box-shadow: none;
        }
      }
    }
  
    &:not(:last-child) {
      &:after {
        position: absolute;
        content: "";
        width: 0.125rem;
        height: 1.5rem;
        background-color: var(--gray-400);
        left: 1.75rem;
        bottom: -1.5rem;
        display: none;
      }
    }
  
    .accordion-body {
      padding: 1.25rem;
    }
  }
  
  .planAccordian {
    .accordion-item {
      border-bottom: 0.0625rem solid var(--gray-900);
  
      &:after {
        display: none;
      }
  
      .accordion-body {
        padding-left: 0;
        padding-top: 0;
      }
  
      .accordion-header {
        .accordion-button {
          border-color: transparent !important;
          padding-bottom: 1.25rem;
          padding-right: 0.75rem;
  
          &:after {
            content: "";
            transform: rotate(0);
            border: 0;
            background-image: url("../images/plus.png");
            width: 1.5rem;
            height: 1.5rem;
            background-repeat: no-repeat;
          }
  
          &:not(.collapsed) {
            &:after {
              background-image: url("../images/minus.png");
            }
          }
        }
      }
    }
  }
  
  .filtersAccordian {
    .accordion-item {
      border-bottom: 0.0625rem solid var(--gray-900);
      background: none;
      border-radius: 0 !important;
      .accordion-body {
        padding: 1.25rem;
    }
      &:last-child {
        border: none;
        padding: 0;
        margin: 0 !important;
        .accordion-body {
          padding: 0rem;
      }
    }
      &:after {
        display: none;
      }
  
      .accordion-button {
        padding-right: 0.3125rem;
        border: 0 !important;
        background-color: transparent !important;
        cursor: pointer;
  
        &:not(.collapsed) {
          &:after {
            transform: rotate(45deg);
  
            top: -0.1875rem;
          }
        }
  
        &:after {
          border-color: var(--gray-100);
          transform: rotate(-45deg);
          // top: 0;
          border-width: 0 0.0.3125rem 0.0.3125rem 0;
          height: 0.375rem;
          width: 0.375rem;
        }
      }
  
      .accordion-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
      }
    }
  }
  
  .employeeDashboardAccordianMain {
    & > .accordion-item {
      &:not(:last-child) {
        &:after {
          display: none;
        }
      }
  
      &.hide {
        .accordion-button {
          padding-right: 0;
  
          &:not(.collapsed) {
            border-radius: 0.375rem;
            border-bottom: 0;
          }
  
          &:after {
            display: none;
          }
        }
  
        .accordion-collapse {
          display: none;
        }
      }
    }
  
    .p-20 {
      padding: 0.875rem 1.25rem;
    }
    .pe-6{
      padding-right:3.75rem ;
    }
  
    .employeeDashboardAccordian {
      background-color: var(--base-100);
  
      .accordion-button {
        // padding: 0 0.625rem 0 0;
  
        &:not(.collapsed) {
          border-bottom: none !important;
        }
  
        .timeTaken {
          min-width: 4.0625rem;
          text-align: right;
        }
      }
  
      .accordion-body {
        .timeTaken {
          min-width: 5rem;
          text-align: right;
        }
      }
  
      .accordion-item {
        box-shadow: none !important;
        border: 0;
        position: relative;
        border-radius: 0;
        .accordion-header {
          position: relative;
        }
  
        &:not(:last-child) {
          &:after {
            // height: calc(100% - 0.625rem);
            // top: 2.375rem;
            // left: 1.25rem;
            height: calc(100% + 0.25rem);
            top: 1.5rem;
            left: 1.3rem;
            bottom: inherit;
            width: 0.0625rem;
            z-index: 99;
          }
  
          &.borderPurple {
            &:after {
              background-color: var(--primary-200) !important;
            }
          }
        }
      }
      .cardContent .count {
        border-color: transparent;
        // width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
  
.accordionProgressBar {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 9.6rem;
  background-color: #F2F2F5;
  height: 0.0625rem;
  span {
    background-color: var(--primary-100);
    height: 0.25rem;
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 100%;
    width: 0;
    border-radius: 1rem;
  }
}
.employeeDashboardAccordianMain{
  .accordion-item:not(:first-of-type){
    border-top: .0625rem solid var(--gray-500);
  }
}