.come-from-modal {

    .modal-header {

        padding: 1.75rem 2.5rem 1.69rem 2rem;
        background: var(--gray-600);

        .btn-close {
            color: var(--gray-100);
            opacity: 1;
            background-size: 0.9rem !important;
            padding: 0;
            padding-right: 0.8rem;

            &:focus {
                box-shadow: none;
            }
        }

        .modal-title {
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: var(--weight-bold);
            &:first-letter {
                text-transform: uppercase;
            }
        }
    }

    .modal-body {
        padding: 2.5rem 2rem 2.5rem;
        background: var(--base-100);
        overflow: auto;
        display: flex;
        flex-direction: column;
    }

}

.assignContent {
    .modal-dialog {
        // max-width: 35.0625rem !important;

    }
}

.createPage {
    .modal-dialog {
        max-width: 1215px;
    }
}



.modal-backdrop.show~.modal-backdrop.show {
    opacity: 0;
}

.modal.addEmployee.assignContent ~ .fade.modal-backdrop.show, .modal.manageTeamsActivityModal ~ .fade.modal-backdrop.show{
    opacity: var(--bs-backdrop-opacity);
    --bs-backdrop-zindex:1056;
    & ~ .modal.modalDialog {
        --bs-modal-zindex:1057;
    }
}
.confirmatinModalExtraBg{
    background: rgba(0,0,0,0.25);
}