.audioPlayer {



    .titleTrack {
        position: absolute;
        top: 215px;
        left: 95px;
        font-weight: var(--weight-bold);
    }

    .rhap_container {
        height: 17.5rem;
        width: 100%;
        padding: 2.5rem 2rem 1rem;
        box-shadow: none;
        margin: 0 auto;
        border-bottom: 1px solid var(--gray-400);
    }

    .rhap_progress-bar {
        height: 6rem;
        background-image: url(../images/audioWave.svg);
        background-repeat: repeat;
        background-size: auto 100%;
        background-color: transparent;

        .rhap_download-progress,
        .rhap_progress-indicator {
            opacity: 0;
        }

        .rhap_progress-filled {
            background-image: url(../images/audioWaveAct.svg);
            background-size: auto 100%;
            background-color: transparent;
            background-repeat: no-repeat;
        }
    }



    .rhap_progress-section {

        position: relative;
        margin-bottom: 2.75rem;

        .rhap_time {
            position: absolute;
            bottom: -40px;
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: var(--weight-medium);
        }

        .rhap_current-time {

            left: 0.9375rem;
        }

        .rhap_total-time {
            right: 0.9375rem;
        }
    }



    .rhap_controls-section {

        .rhap_additional-controls {
            flex: 0 0 auto;
        }

        .rhap_repeat-button {
            display: none;
        }

        .rhap_main-controls {

            .rhap_main-controls-button {


                &.rhap_rewind-button,
                &.rhap_forward-button {
                    display: none;
                }

                &.rhap_play-pause-button {
                    height: 3.25rem;
                    width: 3.25rem;

                    svg {
                        height: 3.25rem;
                        width: 3.25rem;

                        path {
                            fill: var(--primary-200);
                        }
                    }
                }
            }
        }



        .rhap_volume-button {
            svg {

                path {

                    fill: var(--primary-200);
                }
            }
        }

        .rhap_volume-bar {

            background-color: var(--primary-200);
            height: 0.1875rem;
        }

        .rhap_volume-indicator {
            background-color: var(--base-100);
            border: 0.125rem solid var(--primary-200);
        }
    }
}

// doc preview

.docPreview {
    .pg-viewer-wrapper {

        .document-container {
            height: 100%;
            width: 100%;
            background-color: var(--pastel-100);
        }
    }

}

.videoPreview{
    &.docPreview{
        // height: 50vh;
        #react-doc-viewer{
            #header-bar{
                display: none;
            }
        }
        .pdf-canvas{
            display: flex;
            justify-content: center;
        }
    }
}