.employeesDetail {


    .headImg {

        img{
            height: 3.125rem;
            width: 3.125rem;
        }
        span{
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            place-items: center;
            font-size: 1.2rem;
        }
    }

    .userDetails {

        margin: 1.5rem 0 0 0;

        > div {
            margin-right: 4.75rem;
            &.d-flex{
                width: 25%;
            }
        }
        &.userDetailBox{
            div {
                margin-right: 2.75rem;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .d-flex{
            svg{
                min-width: 1.25rem;
            }
        }
    }





}

.assignStatus {

    margin: 0 0.625rem 0.75rem;

    .status {
        position: relative;
        text-transform: uppercase;
        h5.font-medium {
            white-space: break-spaces;
            word-wrap: break-word;
        }

        &:before {
            content: '';
            position: absolute;
            left: -16px;
            top: -2px;
            width: 2px;
            background: var(--secondary-400);
            height: 20px;
        }

        &.blue {
            &:before {
                background: var(--secondary-500);
            }
        }

        &.yellow {
            &:before {
                background: var(--secondary-200);
            }
        }
    }
}

.dropDownCheckBox {

    padding: 20px 0;
    max-height: 100%;

    .dlist {
        padding: 10px 20px;

        &.active {

            background-color: var(--gray-600);
        }
    }
}

.MyLearning{
    flex-direction: column;
}
