.authorContent {
    height: 19.1875rem;
}

.h-70 {
    height: 70%;
}


.forYouCard {
    margin-bottom: 1.5rem;

    &:hover {

        .forYouImg {

            img {
                filter: blur(11px);
                @include transition;
            }

            .viewBtns {

                opacity: 1;

            }
        }
    }
}

.forYouImg {
    position: relative;

    .viewBtns {

        position: absolute;
        opacity: 0;
        @include transition;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}