.sideNav {
  width: 4.5rem;
  background: var(--gray-100);
  padding: 1.25rem 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  @include transition;

  .mainlogo {
      margin-bottom: 1.25rem;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      // padding: 0 1rem 1.25rem;
      padding: 0 0 1.25rem 1rem;
      overflow: hidden;
      width: 3.75rem;

      svg {
          transition: none;
          width: 11.4375rem;
          height: 2.5rem;
          min-width: 11.4375rem;
          // &:nth-child(2) {
          //     display: none;
          // }
      }
  }

  .sideMenu {
      padding: 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0;
      overflow-x: hidden;
      overflow-y: auto;

      li {
          margin-bottom: 1.125rem;
          // text-align: center;
          padding: 0 1rem;

          .svgLight {
              path {
                  stroke: var(--gray-400);
              }
          }

          span {
              display: none;
              white-space: nowrap;
          }

          a {
              // padding: 0.625rem 0;
              position: relative;
              display: block;
              z-index: 1;
              width: 100%;
              font-size: 0.875rem;
              line-height: 1.25rem;
              color: var(--gray-400);
              padding: 0.625rem;
              border-radius: 0.375rem;
              // transition: background-color 0.3s;
              transition: none !important;


              &:hover,
              &.active {
                  background: rgba($base-100, 0.16);
                  .svgLight {
                      // background: rgba($base-100, 0.16);
                      // padding: 0.625rem;
                      // border-radius: 0.375rem;

                      path {
                          stroke: var(--base-100);
                      }
                  }
              }
          }

          &:last-child {
              flex-grow: 1;
              align-items: flex-end;
              display: flex;
              padding: 0 !important;
              margin-bottom: 0.875rem;

              .lastChildli {
                  border-top: 1px solid var(--gray-200);
                  width: 100%;
                  padding: 1.625rem 1rem 0;
              }
          }
      }
  }

  &.active,
  &:hover {
      width: 15rem;

      .mainlogo {
          width: 100%;
          padding: 0 1rem 1.25rem;
          svg {
              &:nth-child(2) {
                  display: block;
              }
          }
      }

      .sideMenu {
          li {
              text-align: left;
              padding: 0 1rem;

              &:last-child {
                  a {
                      &:hover {
                          background: transparent !important;
                          color: var(--base-100);
                      }
                  }
              }

              a {
                  display: flex;
                  align-items: center;
                  gap: 0.75rem;
                  color: var(--gray-400);
                  padding: 0.625rem;
                  border-radius: 0.375rem;
                  // @include transition;

                  &:hover,
                  &.active {
                      background: rgba($base-100, 0.16);
                      font-weight: var(--weight-medium);
                      color: var(--base-100);

                      .svgLight {
                          background: transparent;
                          padding: 0;
                          border-radius: 0;

                          path {
                              stroke: var(--base-100);
                          }
                      }
                  }
              }

              span {
                  display: block;
              }
          }
      }
  }
}

// .sideNav.active + .pages {
//     margin-left: 10.5rem;
// }

.sideNavHover {
  position: fixed;
  height: 100vh;
  z-index: 1052;

  .sideNavExpander {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      padding: 0.75rem;
  }
}