.filters {
  // padding: 2rem 1.25rem;
  background-color: var(--gray-600);
  width: 16.875rem;
  // width: 15%;
  // border-right: 1px solid var(--gray-400);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 99;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  &.filterVisible{
    transform: translateX(0);
    right: 0;
  }
  &.filterHidden{
    transform: translateX(100%);
  }
  .icon-menu {
    display: block !important;
    order: 1;
    span.iconfilmenu,
    span.iconfilarrow {
      padding-left: 0px;
    }
  }
  // .icon-back {
  //     order: 3;
  // }

  .head {
    white-space: nowrap;
  }

  .filter-clear {
    display: flex;
    align-items: center;
    gap: 6px;
    // order: 2;
  }

  .filtersTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap: 10px;
    padding: 1.375rem 2rem 1.25rem 1.625rem;
    .filterCrossBtn {
      svg {
        width: 0.875rem;
        height: 0.875rem;
        path {
          fill: var(--gray-100);
        }
      }
    }
  }
  .filterAccordionArea {
    flex-grow: 1;
    overflow: auto;
    height: 100%;
    padding: 0.25rem 2rem 2rem 1.625rem;
    .accordion-header {
      .accordion-button {
        &::after {
          border-color: var(--gray-100);
          transform: rotate(-45deg);
          height: 0.5rem;
          width: 0.5rem;
          top: 0 !important;
          right: 0;
        }
      }
    }
  }
  .applyArea {
    padding: 1.5rem 2rem 2.5rem 1.625rem;
    button {
      background: var(--primary-100);
      padding: 0.625rem 2rem;
      font-size: 0.875rem;
      font-weight: 700;
      color: white;
      border-radius: 0.375rem;
    }
  }

  // &.hideContent {
  //     width: 4rem;
  //     //flex-grow: initial;
  //     position: absolute;
  //     z-index: 99;
  //     height: 100%;

  //     .filter-clear {
  //         display: none;
  //         @include transition;

  //     }

  //     .head {
  //         display: none;

  //         @include transition;
  //     }

  //     .icon-back {
  //         display: none;
  //         @include transition;

  //     }

  //     .icon-menu {
  //         display: none;
  //         @include transition;

  //     }

  //     .accordion-body {
  //         display: none;
  //         @include transition;
  //     }

  //     .accordion-button {
  //         &:after {
  //             visibility: hidden;
  //         }

  //         &:not(.collapsed) {

  //             border-radius: 0 !important;
  //         }
  //     }

  //     &:hover {
  //         width: 12.125rem !important;

  //         .icon-menu {
  //             display: block !important;
  //             @include transition;

  //         }

  //         .filter-clear {
  //             display: flex !important;
  //             @include transition;

  //         }

  //         .head {
  //             display: inline-block !important;

  //         }

  //         .accordion-body {
  //             display: block !important;

  //         }

  //         .accordion-button {
  //             &:after {
  //                 visibility: visible !important;
  //             }

  //             &:not(.collapsed) {

  //                 border-radius: 2px !important;
  //             }
  //         }
  //     }

  // }
}
.sideFilterall {
  overflow: auto;
  width: 15rem;
}
.filtersContent {
  flex-grow: 1;
  overflow: auto;
  // width: calc(100% - 12.125rem);
  width: 100%;
  // width: calc(100% - 15%);
  display: flex;
  flex-direction: column;
  @include transition;
}

.filterTemplateSideBar {
  padding: 1.668rem 1rem 1.668rem 2.75rem;
}

.hideContent + .filtersContent {
  width: calc(100% - 4rem);
  padding-left: 4rem;
}

.calendar {
  position: relative;

  input {
    padding: 0.625rem 0.75rem;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
}

.filtersAccordian {
  .accordion-item {
    .accordion-collapse {
      min-width: 10.125rem;
    }
  }
}
