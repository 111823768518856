@mixin min($size) {
    @media (min-width: $size) {
        @content;
    }
}

@mixin max($size) {
    @media (max-width: $size) {
        @content;
    }
}

@mixin var($key, $val) {
    #{$key}: map-get($colors, $val);
}

@each $color, $shades in $colors {
    @each $shade, $value in $shades {
        .text-#{$color}-#{$shade} {
            color: #{$value} !important;
        }

        .bg-#{$color}-#{$shade} {
            background: #{$value} !important;
        }

        .border-#{$color}-#{$shade} {
            border-color: #{$value} !important;
        }
    }
}

@each $weight, $value in $fontsWeight {
    .font-#{$weight} {
        font-weight: #{$value} !important;
    }
}

@mixin grid($cols, $mgn) {
    width: ((100% - (($cols - 1) * $mgn)) / $cols);
    margin-right: $mgn;
    margin-bottom: $mgn;

    &:nth-child(#{$cols}n) {
        margin-right: 0;
    }
}

@mixin transition {
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

@mixin objectImg {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

@mixin dFlex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin btn {
    padding: 0.75rem 1.5rem;
    display: inline-block;
    text-align: center;
    border-radius: 0.375rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: var(--weight-bold);
    text-decoration: none;
    transition-delay: 100ms;
    white-space: nowrap;
    @include transition;
}

@mixin btnSm {
    padding: 0.625rem .85rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: var(--weight-bold);
}

@mixin btnXs {
    width: 2rem;
    height: 2rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin btnPrimary {
    @include btn;
    background-color: var(--primary-200);
    color: var(--base-100);
    border-color: var(--primary-200);

    &:hover,
    &:focus,
    &:active {
        box-shadow: 0px 8px 16px rgba(74, 21, 75, 0.12) !important;
        background-color: var(--primary-200) !important;
        color: var(--base-100) !important;
        border-color: var(--primary-200) !important;
    }

    &:disabled {
        background-color: var(--primary-200);
        color: var(--base-100);
        border-color: var(--primary-200);
        opacity: .15;
    }
}

@mixin btnwhite {
    @include btn;
    background-color: rgba($base-100, 0.3);
    color: var(--primary-200);
    // font-weight: var(--weight-medium);
    border: 1px solid rgba($gray-300, 0.3);

    &:hover, &:focus {
        border-color: var(--primary-200);
        background-color: var(--base-100);

        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
        color: var(--primary-200);
    }

    &:disabled {
        background-color: var(--gray-600);
        border-color: var(--gray-400);
        color: var(--gray-400);
        box-shadow: none;

        svg {
            path {
                fill: var(--gray-400);
            }
        }
    }

    // opacity: .3;
}

@mixin btnNobg {
    @include btn;
    background-color: transparent;
    color: var(--primary-200);
    box-shadow: none;
    border: none;

    &:hover, &:focus {
        background-color: transparent;
        box-shadow: none;
        border: none;
        color: var(--primary-200);
    }
}

@mixin btnSecondary {
    @include btn;
    background-color: var(--primary-100);
    color: var(--base-100);
    border-color: var(--primary-100);

    &:hover,
    &:active,
    &:focus {
        background-color: var(--primary-400);
        box-shadow: 0px 8px 16px rgba(241, 88, 69, 0.12);
        border-color: var(--primary-100);
        color: var(--base-100);
    }

    &:disabled {
        box-shadow: none;
        background-color: var(--primary-100);
        color: var(--base-100);
        border-color: var(--primary-100);
        opacity: .15;
    }
}

@mixin btnLink {
    text-decoration: none;
    color: var(--primary-200);

    &:hover {
        color: var(--primary-200);
    }
}
