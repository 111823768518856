.activityLog {

    .activity {

        display: flex;
        gap: 2.4375rem;
        align-items: center;

        .timeSection {
            display: flex;
            gap: 0.75rem;

            .timerPipe {
                position: relative;

                &:after {

                    position: absolute;
                    content: '';
                    width: 0.0625rem;
                    height: 1.5rem;
                    background-color: var(--gray-400);
                    bottom: -1.5rem;
                    left: 50%;
                    transform: translateX(-50%);

                }
            }

            .time {
                min-width: 4.0625rem;
            }
        }

        .userDetails {
            display: flex;
            gap: 0.75rem;
            align-items: center;

            .logRedirect {
                color: var(--gray-100);
                display: flex;
                gap: 0.8125rem;


                .logRedirectText {
                    position: relative;
                    display: flex;

                    &:after {
                        content: '';
                        width: 100%;
                        border-bottom: 0.0625rem dashed var(--gray-300);
                        display: inline-block;
                        position: absolute;
                        bottom: 0;
                    }
                }

                &:hover {
                    color: var(--primary-200);

                    .iconGo {
                        display: block !important;
                    }

                }
            }

        }
    }

}





/*dahboard*/

.assignBar {
    width: 100%;
    height: 1.875rem;
    display: block;
    min-width: 0.125rem;
    &.pink {

        background-color: var(--secondary-400);
    }

    &.purple {

        background-color: var(--secondary-800);
    }

}
.min-100{
    width: calc(100% - 6.25rem);
}

.mostPopularCount {
    background-color: var(--gray-500);
    border-radius: 1rem;
    padding: 0.25rem 0.7rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--gray-1200);
    &.decreaseCount{
        span{
            color: var(--secondary-600) !important;
        }
        svg{
            transform: rotate(90deg);
            path{
                stroke: var(--secondary-600) !important;
            }
        }
    }
}

.dashboardBg {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background-image: url('../images/dashboardWidgetBg.png');
        width: 8.375rem;
        height: 8.5rem;
    }
}

.pipeSepration {
    position: relative;

    &:after {
        content: '';
        left: 0;
        bottom: -0.625rem;
        position: absolute;
        width: 1.25rem;
        height: 0.125rem;
        background-color: var(--secondary-400);
    }
    &.averageTime{
        &:after{
            background-color: var(--gray-300);
        }
    }
}

.newJoining {
    border-bottom: 0.0625rem solid #e8e8e8;
    padding-bottom: 1rem;
    margin: 1rem 2rem 1rem 0;
    .headImg{
        margin-right: 1.25rem;
        img {
            width: 4rem;
            height: 4rem;
        }
    }
}
.text-12 {
    font-size: .75rem;
    line-height: normal;
}
.newJoinInfo{

    li {
        position: relative;
        padding-left: 1.8rem;
        margin-bottom: 1rem;
        line-height: normal;
        display: block;
        svg {
            position: absolute;
            left: 0;
            top: 0.08rem;
            width: 1.125rem !important;
            height: 1.125rem !important;
        }
    }
}
.unassignedJoinPopupInfo{
    padding-top: 2rem;
    position: sticky;
    left: 0;
    top: 0;
    background: var(--base-100);
    z-index: 1;
}
.newJoiningJoinPopupInfo{
    padding-top: 2rem;
    position: sticky;
    left: 0;
    top: 0;
    background: var(--base-100);
    z-index: 1;
}
.conteHeading {
    width: 10rem;
}
.conteHeadingNext{
    width: calc(100% - 11rem);
}
.conteHeadingModal{
    width: 28rem;
}
.conteHeadingh{
    width: 10rem;
}
.conteHeadinghNext{
    width: calc(100% - 11rem);
}
.conteHeadinghModal{
    width: 28rem;
}
.popularContantMod{
    box-shadow: 0 0 1.25rem 0 rgba(40, 43, 45, 0.05);
    border: 0.0625rem solid var(--gray-400);
    border-radius: 0.375rem;
    overflow: auto;
    height: calc(100vh - 9.50rem);
    .HeadeingBar {
        background-color :var(--gray-600);
        padding: 0.3rem 1rem;
        margin-bottom: 1.8rem;
        position: sticky;
        left: 0;
        top: 0;
        border-radius: 0.375rem 0.375rem 0 0;
    }
    .bodyContent {
        padding: 0rem 1rem;
    }
}
.me-12 {
    margin-right: 0.4rem;
    margin-left: 0.4rem;
}
.px-12{
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}
.helpEmail {
    min-width: 44%;
    margin-left: 6%;
}
.helpDeskSec{
    max-height: 26.75rem;
    overflow: auto;
    .helpDeskDiv{
        &:last-child {
            border: none;
            padding: 0 !important;
            margin: 0 !important;
        }
    }
    .helpDeskSecTitle{
        background: var(--base-100);
        position: sticky;
        left: 0;
        top: 0;
    }
}
.EmployeeNotification{
    &.authoringNav{
        padding-top: 32px;
        position: sticky;
        left: 0;
        top: 0;
        background: #FFF;
    }
}
.NotificationNodataHeight{
    height: calc(100vh - 15rem);
}
.mx-1m {
    margin: 0 -0.4rem;
}
.max-298 {
    max-height: 18.6rem;
    object-fit: cover;
}
.px-45{
    padding-left: 2.6rem;
    padding-right: 2.6rem;
}
.px-46{
    padding-left: 2.875rem;
    padding-right: 2.875rem;
}
.journyCardshadow {
    background-color: var(--base-100);
    border: 0.0625rem solid var(--gray-500);
    box-shadow: -0.125rem 0.125rem 14px 0 rgba(40,43,45,.05);
    border-radius: 0.375rem;
    overflow: hidden;
    .exp{
        span{
            &:not(:last-child){  
                margin-right: .5rem;
                padding-right: .5rem;
                border-right: 0.0625rem solid var(--gray-500);
            }
            b{
                color: var(--gray-100);
                padding-right: .25rem;
                font-weight: normal;
            }
            &:last-child{
                b{
                    padding-left: .25rem;
                    padding-right: 0;
                }
            }
        }
     
    }
}
.expdate{
    span{
        &:not(:last-child){  
            margin-right: .5rem;
            padding-right: .5rem;
            border-right: 0.0625rem solid var(--gray-500);
        }
        b{
            color: var(--gray-100);
            padding-right: .25rem;
            font-weight: normal;
        }
        &:last-child{
            b{
                padding-left: .25rem;
                padding-right: 0;
            }
        }
    }
 
}
.journyCard {
    height: 100%;
    word-break: break-word;
    position: relative;
    .journyCardDetail{
        position: relative;
        padding-top: 1.6rem;
        padding-bottom: 1.9rem;

        p{
            margin: 0;
            line-height: normal;
        }
        span.tableTag {
            position: absolute;
            top: -0.75rem;
        }
        .exp {
            margin-bottom: 0.7rem;
        }
    }
    .accordionProgressBar {
        background-color: transparent;
        left: 0;
    }
}

.dashboadSel {
    .react-datepicker{
        &__month{
            margin: 0;
        }
        &__header{
            background-color: #fff;
            border: none;
            padding-top: 1.25rem;
        }
        &__triangle{
            &:after{
                border-bottom-color: #fff !important;
            }
        }
        &__navigation{
            &--previous{
                left: 0.4375rem;
            }
            &--next{
                right: 0.4375rem;
            }
            top: 17px;
            display: flex;
            padding: 0;
            align-items: center;
            text-align: center;
            &-icon{
                &:before{
                    border-width: 1.5px 1.5px 0 0;
                    border-color: #232323;
                }
            }
        }
        &__month-text{
            &:hover{
                background-color: #f8f8f8;
            }
            display: inline-block;
            width: 5.1rem;
            margin: 0.125rem;
            align-items: center;
            padding: 0.9rem 0;
            font-size: 0.875rem;
            &--keyboard{
                &-selected{
                    background-color: #f8f8f8;
                    font-weight: bolder;
                    color: #000;
                    &:hover{
                        background-color: #f8f8f8;
                    }
                }
            }
        }
    }
    .select,.react-datepicker-wrapper {
        width: 50%;
        margin-left: 1.125rem;
        .react-datepicker{
            &__input-container{
                position: relative;
                &:before{
                    content: "";
                    position: absolute;
                    width: 0.0625rem;
                    height: 1.15rem;
                    background-color: #ccc;
                    right: 2.625rem;
                    top: 0.90625rem;
                }
                &:after{
                    content: "";
                    border: solid var(--gray-100);
                    border-width: 0 0.0625rem 0.0625rem 0;
                    display: inline-block;
                    transform: rotate(45deg);
                    position: absolute;
                    padding: 0.25rem;
                    right: 1.2rem;
                    top: 1rem;
                    
                }
                input.calendar-dropdown{
                    /*
                    background-image: url('../images/downIcon.png');
                    background-repeat: no-repeat;
                    background-position: center right 0.9375rem;
                    background-size: 0.8125rem;
                    */
                    cursor: pointer;
                    min-height: 3rem;
                    // new
                    // padding-right: 2.5rem;
                }
            }
        }
        // .recatMySel{
        //     &__control{
        //         border-radius: 2rem;
        //     }
        // }
    }
}

.mulSelCustom{
    .searchWrapper {
       
            background-image: url('../images/downIcon.png');
            background-repeat: no-repeat;
            background-position: top 1.25rem right 0.9375rem;
            background-size: 0.8125rem;
            cursor: pointer;
            padding-right: 2.375rem;
    }
}
.emptyDivs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    padding: 0 1.875rem;
     img {
        max-height: 6.5625rem;
        margin-bottom: 0.625rem;
    }
}
.wordWrap {
    word-wrap: break-word;
}
.avgDaysComplete {
    min-width: 10.8125rem;
}

.teamViseBarChart {
    // height: 100%;
    // height: 10.25rem;
    height: 14rem;
    svg {
        height: 100%;
        /*
        .apexcharts-series {
            [selected="true"] {
                fill: var(--secondary-400);
                filter: none;
            }
        }
        */
        // .apexcharts-grid-borders {}
       
    }
}

.text-with-tggolebtn{
    display: flex;
    .btn-color-mode-switch{
        display: inline-block;
        margin: 0;
        position: relative;
    }
    
    .btn-color-mode-switch > label.btn-color-mode-switch-inner{
        margin: 0;
        width: 9rem;
        height: 2.188rem;
        background: #BFBFBF;
        border-radius: 0.375rem;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease;
        display: block;
        color: #7B7B7B;
        border: 0.063rem solid #AEAEAE;
        font-size: 0.75rem;
        font-weight: 400;
        cursor: pointer;
    }
    
    .btn-color-mode-switch > label.btn-color-mode-switch-inner:before{
        content: attr(data-on);
        position: absolute;
        font-size: 0.75rem;
        font-weight: 500;
        top: 0.25rem;
        right: 1.25rem;
        display: flex;
        justify-content: center;
    }
    .btn-color-mode-switch > label.btn-color-mode-switch-inner:after{
        content: attr(data-off);
        width: 4.5rem;
        height: 2.1rem;
        background: #fff;
        // border-radius: 0.375rem 0 0 0.375rem;
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        transition: all .3s ease;
        padding: 0.313rem 0;
        color: #3c3c3c;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn-color-mode-switch input[type="checkbox"]{
        cursor: pointer;
        width: 2.188rem;
        height: 2rem;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 0;
        left: 0;
    }
    
    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner{
        // background: #FFF;
        // color: #fff;
    }
    
    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after{
        content: attr(data-on);
        left: 4.5rem;
        background: #FFF;
        // border-radius: 0.375rem 0 0 0.375rem;
    }
    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before{
        content: attr(data-off);
        right: auto;
        left: 1.25rem;
    }
}
.calendar-dropdown{
    font-size: 0.875rem;
    line-height: 0.875rem;
    min-height: 2.625rem !important;
}
  
 