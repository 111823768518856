.progress {

    background: var(--gray-500);
    height: 0.75rem;
    border-radius: 0.1875rem;

    &.tableProgress {
        height: 6px;
    }




    .progress-bar {
        background-color: var(--secondary-300);

        &.yellow {
            background-color: var(--secondary-200);
        }

        &.orange {

            background-color: var(--primary-100);
        }

        &.purple {

            background-color: rgba($secondary-800, 0.3);

        }
    }

}

.mainProgressBar {
    .col {
        width: 260px;
        flex: initial;
        max-width: 100%;
    }


    .percent {
        min-width: 2rem;
    }
}
th.W40 {
    width: 40%;
}
th.W15 {
    width: 15%;
}
th.W45 {
    width: 45%;
}
td.diComent{
    p {
        word-wrap: break-word;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}