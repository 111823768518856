.authoringNav {
  padding: 0;
  margin: 0;
  display: flex;
  &.authoringNavUpdate {
    margin-left: 3.75rem;
    li{
        margin-top: 1.75rem;
        button, a{
            padding: 0 0 1.75rem 0;
            border: none;
            &.active{
                &::after{
                    bottom: -0.125rem;
                }
            }
        }
    }
  }
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    a,
    button {
      display: block;
      padding-bottom: 1.25rem;
      position: relative;
      &.active {
        font-weight: var(--weight-bold);
        &:after {
          content: "";
          height: 0.125rem;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: var(--primary-100);
        }
      }
    }
  }
  &.authoringNavSm {
    li {
      a,
      button {
        padding-bottom: 0.75rem;
      }
    }
  }
  &.modalTab {
    position: sticky;
    top: 0;
    li {
      margin-right: 1.5rem !important;
      button {
        border: none !important;
        padding: 0px !important;
        color: #000;
        padding-bottom: 1.25rem !important;
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
}

.empAnav {
  li {
    margin-right: 1.188rem;
    a {
      padding-bottom: 0.75rem;
    }
  }
}
