.table {
  width: 100%;

  // .checkBox {
  //     padding-left: 0;

  //     .checkBoxCheck {
  //         position: static;
  //     }
  // }

  tbody {
    tr {
      @include transition;

      &:hover td,
      &.checkedRow td {
        background-color: var(--gray-600);
      }
    }
  }

  th,
  td {
    &.w15rem {
      a {
        width: 14rem;
        word-break: break-word;
        p {
          word-break: break-word;
        }
      }
    }
    border: 0;
    vertical-align: middle;

    // padding-left: 1.25rem;
    // padding-right: 1.25rem;
    &:first-child {
      padding-left: 2rem;
      // padding-right: 0px;
    }

    &:last-child {
      padding-right: 2rem;
    }
  }

  th {
    padding: 0.75rem 1.25rem;
    background-color: var(--gray-600);
    font-size: 0.75rem;
    color: var(--gray-300);
    font-weight: var(--weight-bold);
    white-space: nowrap;
    position: sticky;
    top: 0;
    z-index: 9;
    border-bottom: 1px solid var(--gray-400);
  }

  td {
    padding: 0.75rem 1.25rem;
    font-size: 0.8125rem;
    color: var(--gray-100);
    line-height: 1.325rem;
    .descrp {
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // width: 5.375rem;
      width: 10rem;
    }

    a {
      clear: both;
      display: inline-block !important;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // width: 5.375rem;
    }
  }
}
.whiteNo {
  white-space: inherit;
}
.prefTable {
  tr {
    &:last-child{
      td{
        border: 0;
      }
    }
    th {
      border-bottom: 1px solid var(--gray-500);
      &.w-113 {
        max-width: 113px;
      }
    }
    td {
      border-bottom: 1px solid var(--gray-500);
    }
  }
}
.tableTag {
  padding: 0.25rem 0.625rem;
  background-color: var(--pastel-600);
  border-radius: 1rem;
  color: var(--secondary-500);
  font-weight: var(--weight-medium);
  display: flex;
  width: max-content;
  svg {
    margin-right: 0.25rem;
    display: none;
  }

  &.green {
    background-color: var(--pastel-500);
    color: var(--secondary-300);
    svg {
      display: inline-block;
    }
  }

  &.orange {
    background-color: var(--pastel-100);
    color: var(--primary-100);
  }

  &.gray {
    background-color: var(--gray-500);
    color: var(--gray-200);
  }

  &.purple {
    background-color: var(--pastel-400);
    color: var(--primary-200);
  }

  &.blue {
    background-color: var(--pastel-600);
    color: var(--secondary-500);
  }
}

.tableName {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--gray-500);
  color: var(--gray-300);
  text-align: center;
  line-height: 2rem;
  font-size: 0.75rem;
  font-weight: var(--weight-medium);
  min-width: 2rem;
}

.tableOuter {
  border: 1px solid var(--gray-400);
  box-shadow: 0px 0px 20px rgba(40, 43, 45, 0.05);
  border-radius: 0.375rem;
  flex-grow: 1;
  overflow: hidden;
}

.reportIssuesAct {
  max-width: 6.25rem;
}

.tableComponent {
  // border-top: 1px solid var(--gray-400);
}
th.thProgress {
  min-width: 9rem;
}
.max9rem {
  width: 9rem;
}
.max10rem {
  width: 10rem;
}
.max11rem {
  width: 11rem;
}
.blockdiv {
  display: block;
  line-height: normal;
  padding: 0.1rem 0;
}

.teamDeatilsTable {
  width: 100%;
  tr {
    td {
      padding: 0.5rem 0.625rem;
      font-size: 0.875rem;
      color: #3C3C3C;
      &:first-child{
        padding-left: 0;
      }
    }
  }
}

.preferencesTable{
  table{
    thead{
      tr{
        th{
          z-index: 1;
        }
      }
    }
  }
}