html {
    font-size: $fontSize;

    @include min(1200px) {
        font-size: $fontSize - 2;
    }

    @include min(1366px) {
        font-size: $fontSize - 1;
    }

    @include min(1400px) {
        font-size: $fontSize;

    }
/*
    @include min(1440px) {
        font-size: 1.111vw;
    }
    */
    
    @include min(1921px) {
        font-size: 0.833vw;
    }
    


}

body {
    font-family: $primaryFont;
    font-size: 1rem;
    line-height: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: var(--weight-bold);
    &:first-letter {
        text-transform: uppercase
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: var(--weight-normal);
    margin: 0;
    &:first-letter {
        text-transform: uppercase
    }
}

h1,
.h1 {
    // font-size: 32px;
    // line-height: 40px;
    font-size: 2rem;
    line-height: 2.5rem;
}

h2,
.h2 {
    // font-size: 24px;
    // line-height: 32px;
    font-size: 1.5rem;
    line-height: 2rem;
}

h3,
.h3 {
    // font-size: 20px;
    // line-height: 24px;
    font-size: 1.25rem;
    line-height: 1.5rem;
}

h4,
.h4 {
    // font-size: 18px;
    // line-height: 24px;
    font-size: 1.125rem;
    line-height: 1.5rem;
}

h5,
.h5 {
    // font-size: 16px;
    // line-height: 24px;
    font-size: 1rem;
    line-height: 1.5rem;
}

h6,
.h6 {
    // font-size: 14px;
    // line-height: 20px;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-sm {
    // font-size: 13px;
    // line-height: 18px;
    font-size: 0.8125rem !important;
    line-height: 1.125rem;
}

.text-md {
    // font-size: 12px;
    // line-height: 16px;
    font-size: 0.75rem;
    line-height: 1rem;
}
.text-lg {
    // font-size: 14px;
    // line-height: 16px;
    font-size: 0.875rem;
    line-height: 1rem;
}
// label, 
.label,
.text-xs {
    // font-size: 10px;
    // line-height: 12px;
    font-size: 0.625rem;
    line-height: 0.75rem;
}


.text-xl {
    // font-size: 28px;
    // line-height: 33px;

    font-size: 1.75rem;
    line-height: 2.0625rem;
}

.myTextarea {
    label.h5 {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}


.f24{font-size: 1.5rem;}
.f40{font-size: 2.5rem;}
.text-success{
    &-100{
       color:#2EB67D;
    }
}