.tooltipProgressBar {

    background-color: transparent;

    &.show {
        opacity: 1;
    }

    .tooltip-arrow {
        --bs-tooltip-bg: var(--base-100);
    }

    .tooltip-inner {
        width: 238px;
        color: var(--gray-100);
        background-color: var(--base-100);
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
        border: 1px solid var(--gray-500);
        padding: 0;

        div {
            display: flex;
            justify-content: space-between;
            padding: 16px 20px;


            &.active, &:hover {
                background: var(--gray-600);

                p {
                    font-weight: var(--weight-bold);
                }

                span {
                    font-weight: var(--weight-extraBold) !important;
                }
            }


        }

    }
}


.tootltipCreateModule {
    position: relative;
    margin: 20px 0 10px 20px;

    .crossBtn {
        position: absolute;
        top: 1.875rem;
        right: 1.5625rem;
    }

    &.bs-tooltip-end {

        .tooltip-arrow {


            top: -30px !important;

        }

    }

    &.bs-tooltip-bottom {

        padding-right: 0;

        .tooltip-arrow {
            left: 10px !important;

        }
    }


    .tooltip-arrow {
        --bs-tooltip-bg: var(--base-100);


        &:before {
            right: -1px;
            border-width: 1rem;
        }
    }



    .tooltip-inner {
        max-width: 600px;
        color: var(--gray-100);
        background-color: var(--base-100);
        box-shadow: 0px 0px 58px rgba(0, 0, 0, 0.1);
        border: 1px solid var(--gray-500);
        padding: 0;
        border-radius: 10px;
        overflow: hidden;



    }
}

.errorValidation {
    margin: 0;
    padding: 0;
    line-height: normal !important;
    font-size: .75rem !important;
    margin-top: 0.25rem !important; 
    color: red !important;
    font-weight: normal !important; 
}