body {
  color: var(--gray-100);

  // scroll
  ::-webkit-scrollbar {
    width: 0.19rem;
  }

  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 0.375rem rgba(0,0,0,0.3);
    background-color: var(--gray-600);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    outline: none;
    border-radius: 0.275rem;
    width: 0.2rem;
  }

  ::-webkit-scrollbar:horizontal {
    height: 0.375rem;
  }
}

.container-fluid {
  padding: 0 $continerPadding;
}

.container {
}

.z-index-1 {
  z-index: 1;
}

.z-index-1051 {
  z-index: 1051;
}

.flex-grow-1 {
  flex-grow: 1;
}

a,
button {
  outline: none;
  @include transition;
}

button {
  white-space: nowrap;
}

ul,
ul li {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
}

.btn-primary {
  @include btnPrimary;
}

.btn-white {
  @include btnwhite;
}

.btn-secondary {
  @include btnSecondary;
}

.btn-secondary2 {
  @include btnSecondary;
  background-color: var(--primary-200);
  border-color: var(--primary-200);

  &:hover {
    background-color: #320b33;
    border-color: var(--primary-200);
  }
}

.px-32 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.ps-32 {
  padding-left: 2rem !important;
}

.pb-24 {
  padding-bottom: 1.5rem !important;
}
.px-80{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}
.ps-80{
    padding-left: 5rem !important;
}
.pe-80{
    padding-right: 5rem !important;
}
.btn-noBg {
  @include btnNobg;
}

.btn-sm {
  @include btnSm;
}

.btn-xs {
  @include btnXs;
}

.btn-link {
  @include btnLink;
}

.form-group {
  margin-bottom: 1rem;
  // margin-bottom: 1.5rem;
  position: relative;
}

.cardType {
  border-radius: 0.375rem !important;
}

.input,
.cardType,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: var(--base-100);
  border: 0.0625rem solid var(--gray-400);
  border-radius: 0.375rem;
  box-shadow: none;
  outline: none;
  color: var(--gray-100);
  font-size: .875rem;
  line-height: 1rem;

  &::placeholder {
    color: var(--gray-300);
  }

  &:focus {
    border-color: var(--primary-200);
    // box-shadow: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.12);
    box-shadow: none;
  }

  &.error {
    border-color: var(--primary-100);
  }

  &.input_alert {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    & + .alert {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.input_valid {
  position: absolute;
  right: 0.875rem;
  top: 50%;
  transform: translateY(-50%);

  svg {
    display: block;
  }
}

.chatBtn {
  position: fixed;
  bottom: 1rem;
  right: 1.5rem;
  z-index: 9;
}

.alert {
  padding: 0.875rem 1rem 0.875rem 3rem;
  text-align: left;
  background-repeat: no-repeat;
  background-position: 1rem 0.75rem;
  border-width: 0.0625rem;
  border-style: solid;

  &.alert-warning {
    background-color: var(--primary-100);
    border-color: var(--primary-100);
    color: var(--base-100);
    border-radius: 0.375rem;
    background-image: url(../images/icon_warning.svg);
  }
}

.checkBox {
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.md {
    .checkBoxCheck {
      width: 1.25rem;
      height: 1.25rem;
    }

    label {
      margin-left: 0.625rem;
    }
  }

  .checkBoxCheck {
    border: 0.0625rem solid var(--gray-400);
    border-radius: 0.25rem;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: top;
    position: absolute;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    @include transition;
  }

  input[type="checkbox"] {
    display: none;

    &:checked {
      & ~ .checkBoxCheck {
        background-color: var(--gray-100);
        border: 0.0625rem solid var(--gray-100);
        background-image: url(../images/check_white.svg);
        background-size: 0.75rem 0.625rem;
      }
    }

    &:disabled {
      & ~ .checkBoxCheck {
        background-color: var(--gray-100);
        border: 1px solid var(--gray-100);
        background-image: url(../images/check_white.svg);
        opacity: 0.2;
        background-size: 0.75rem 0.625rem;
      }
    }
  }
}

.contect {
  position: relative;
  padding-left: 4.5rem;
  background-color: var(--gray-500);
  height: 100vh;
  @include transition;

  &.hideSidenav {
    padding-left: 0;

    .sideNav {
      display: none;
    }

    .pageOuter {
      background: var(--base-100);
    }

    .pageHeader {
      margin-bottom: 0;
      border-bottom: 0.0625rem solid var(--gray-400);

      .container-fluid {
        padding-right: 2.75rem;
      }
    }
  }

  .pages {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .col-lg-9 {
      .pages {
        height: auto;
      }
    }

    .pages {
      .videoPreview.fullCol {
        margin: 0rem 2.5rem;

        .myInput {
          textarea {
            max-height: 4.5rem;
          }
        }

        .col-6,
        .col-7,
        .col {
          .pages {
            .videoPreview {
              margin: 0;
              margin-bottom: 1rem;
            }
          }

          .myInput {
            max-width: 100%;
            position: relative;
            width: 100%;
            display: block;

            // .mt-3 {
            //     margin: 0 !important;
            // }
          }

          .mySelect {
            .selectCustom__control {
              padding: 0.5rem 1rem;
            }
          }
        }
      }
    }

    @include transition;

    .pageOuter {
      flex-grow: 1;
      overflow: auto;
      padding-bottom: 2rem;
      display: flex;
      flex-direction: column;

      & > .container-fluid {
        // min-height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
    }

    .page {
      // padding: 0 $continerPadding;
      background-color: var(--base-100);
      border: 0.0625rem solid var(--gray-400);
      box-shadow: 0 0 1.25rem rgba(40, 43, 45, 0.05);
      border-radius: 0.375rem;
      overflow: hidden;
      flex-grow: 1;
      overflow: auto;
      // min-height: 100%;
      position: relative;
    }
  }

  &.navOpen {
    padding-left: 15rem;
  }
}

.myInput {
  .svgUploadicon {
    svg {
      position: inherit;
      top: auto;
      bottom: auto;
      transform: inherit;
    }
  }
}

.pageActions {
  padding: 1.5rem;
  background-color: var(--base-100);
  // border-bottom: 0.0625rem solid var(--gray-400);
}

.v-align-bottom {
  vertical-align: bottom;
}

.divider {
  width: 0.0625rem;
  // height: 100%;
  background-color: var(--gray-400);
}

.formView {
  .pageOuter {
    form {
      .col-lg-9.col {
        margin-bottom: 2rem;
      }
    }
  }
}

/*modal css*/

.come-from-modal.right {
  .modal-dialog {
    margin-right: 0;
    height: 100vh;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 85%;
    /*for transition*/
    opacity: 0;
    // right: -1.875rem;
    // -webkit-transition: all 0.3s;
    // -moz-transition: all 0.3s;
    // transition: all 0.3s;
    transform: translateX(100%);
    @include transition;

    .modal-content {
      height: 100%;
      border-radius: 0;
      border: 0;
    }
  }

  &.show {
    .modal-dialog {
      // right: 0;
      height: 100%;
      overflow: auto;
      opacity: 1;
      // transition: opacity 0.35s;
      // transition: right 0.35s;
      transform: translateX(0);
    }
  }
}

.modal-header {
  justify-content: end;
  background-color: var(--gray-600);

  .modal-backdrop {
    opacity: 0.6 !important;
  }
}

.selectLins {
  .recatMySel__menu {
    .recatMySel__menu-list {
      .recatMySel__option {
        &:nth-child(2) {
          border-bottom: 0.0625rem solid #ececec;
        }

        &:nth-child(5) {
          border-bottom: 0.0625rem solid #ececec;
        }

        &:nth-child(6) {
          border-bottom: 0.0625rem solid #ececec;
        }
      }
    }
  }
}

/*common input*/
.myInputbox {
  max-width: 27.5rem;
}

.h6,
p,
label {
  span.mandatoryFields {
    padding-left: 0.25rem;
  }
}

.myInput {
  max-width: 27.5rem;
  position: relative;

  .errorValidation {
    margin: 0;
    padding: 0;
    line-height: normal;
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }

  label {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: var(--weight-medium);
    display: block;
    margin-bottom: 0.5rem;
    width: 100%;

    span {
      padding-left: 0.25rem;
    }
  }

  input,
  textarea {
    border-radius: 0.375rem !important;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
    line-height: 1rem;
    // line-height: 1.25rem;

    &::placeholder {
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--gray-300);
    }

    &:focus {
      border-color: var(--primary-200);
      // box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.08) !important;
      box-shadow: none !important;
    }

    &:disabled {
      // background: var(--gray-400);
      background: var(--gray-600);
      border-color: var(--gray-400);
      color: var(--gray-300);
    }
  }

  svg {
    position: absolute;
    right: 0.625rem;
    // top: 50%;
    top: 3.33rem;
    transform: translateY(-50%);
    z-index: 9;
  }
}

.updatePassword {
  .myInput {
    svg.bi-eye {
      top: 3.33rem;
    }
  }
}

.myInput {
  .react-datepicker {
    &__month {
      margin: 0px;
    }

    &__header {
      background-color: #fff;
      border: none;
      padding-top: 20px;
    }

    &__triangle {
      &:after {
        border-bottom-color: #fff !important;
      }
    }

    &__navigation {
      &--previous {
        left: 7px;
      }

      &--next {
        right: 7px;
      }

      top: 17px;
      display: flex;
      padding: 0px;
      align-items: center;
      text-align: center;

      &-icon {
        &:before {
          border-width: 1.5px 1.5px 0 0;
          border-color: #232323;
        }
      }
    }

    &__month-text,
    &__day {
      &:hover {
        background-color: #f8f8f8;
      }

      &--keyboard {
        &-selected {
          background-color: #f8f8f8;
          font-weight: bolder;
          color: #000;

          &:hover {
            background-color: #f8f8f8;
          }
        }
      }

      &--selected {
        &:hover {
          color: #fff;
          background: #216ba5;
        }
      }
    }
  }
}

/*topAccountdropdownselect*/
.accountDropdown,
.tableDropdown {
  .dropdown-menu {
    box-shadow: 0 0.375rem 0.5rem rgba(0, 0, 0, 0.04);
    border: 0.0625rem solid var(--gray-500);

    .dropdown-item {
      font-size: 0.8125rem;
      line-height: 1.125rem;
      @include transition;
      color: var(--gray-100);

      &:hover,
      &.active {
        background-color: var(--gray-600);
        // font-weight: var(--weight-medium);
      }

      &.active {
        font-weight: var(--weight-medium);
      }
    }
  }
}

.tableDropdown {
  .dropdown-item {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 1rem;
  }
}
.accountDropdown{
  .dropdown-item{
    &.active {
      background-color: unset !important;
    }
    &:hover{
      background-color: var(--gray-600) !important;
    }
  }
}

/*common select*/
.mySelect {
  max-width: 27.5rem;

  &.topSelect {
    .select {
      .css-1s2u09g-control,
      .css-1pahdxg-control {
        background-color: var(--gray-500);
        border: 0.0625rem solid var(--gray-400);
        border-radius: 1.5625rem;
      }

      .css-1pahdxg-control {
        box-shadow: none !important;
      }

      .css-qc6sy-singleValue {
        font-size: 0.8125rem;
        line-height: 1.125rem;
      }

      .css-319lph-ValueContainer {
        padding: 0.4375rem 0 0.4375rem 0.75rem;
      }

      /*menu*/
      .css-1w9j89e-menu,
      .css-26l3qy-menu {
        .css-yt9ioa-option {
          font-size: 0.75rem;
          line-height: 0.75rem;
        }

        .css-1n7v3ny-option,
        .css-9gakcf-option {
          font-size: 0.75rem;
          line-height: 0.75rem;
        }

        .css-9gakcf-option {
          font-weight: var(--weight-medium);
        }
      }

      /*indicators*/
      .css-1hb7zxy-IndicatorsContainer {
        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
          padding: 0;

          svg {
            display: none;
          }
        }

        &:after {
          content: "";
          border: solid var(--gray-300);
          border-width: 0 0.0625rem 0.0625rem 0;
          display: inline-block;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          position: relative;
          padding: 0.25rem;
          margin: -0.1875rem 0.8125rem 0 0.5rem;
        }
      }
    }
  }

  & > label {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: var(--weight-medium);
    display: block;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .select {
    max-width: 32.5rem;

    &:focus-visible {
      outline: none;
    }
  }

  .selectCustom {
    &__control {
      padding: 0.5rem 1rem;
      box-shadow: none !important;
      font-size: 0.875rem;
      line-height: 0.875rem;
      white-space: nowrap;
      border-radius: 0.375rem;
      border-color: var(--gray-400);
      min-height: 2.625rem;
      &:hover {
        border-color: var(--gray-400);
      }

      &--menu-is-open {
        .selectCustom__indicator {
          &__indicator-separator {
            display: none;
          }

          svg {
            display: none;
          }

          &:after {
            content: "";
            border: solid var(--gray-100);
            border-width: 0 1px 1px 0;
            display: inline-block;
            transform: rotate(224deg);
            -webkit-transform: rotate(224deg);
            position: relative;
            padding: 0.25rem;
            margin: 0.1875rem 0 0 0.5rem;
          }
        }
      }
    }

    &__value-container {
      padding: 0;
    }

    &__placeholder {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__indicator-separator {
      opacity: 0;
    }

    &__menu {
      box-shadow: 0 0.375rem 0.5rem rgba(0, 0, 0, 0.04);
      border: 0.0625rem solid var(--gray-500);
      z-index: 99;
      margin: 0.0625rem 0;
    }

    &__menu-list {
      overflow: auto;
      height: 100%;
      padding: 0;
      max-height: 10.3125rem;
    }

    &__option {
      font-weight: var(--weight-medium);
      color: var(--gray-100);
      background: var(--base-100);
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding: 0.75rem 1rem;

      &:not(:last-child) {
        border-bottom: 0.0625rem solid var(--gray-400);
      }

      &:focus-visible {
        outline: none;
      }

      &:active,
      &:hover {
        background-color: var(--gray-500);
      }
    }

    &__option--is-selected {
      background-color: var(--gray-500);
      font-weight: var(--weight-bold);
    }

    &__option--is-disabled {
      opacity: 0.5;
    }

    &__placeholder {
      color: var(--base-200);
    }

    &__dropdown-indicator {
      padding: 0rem;
      cursor: pointer;
    }

    &__indicator {
      &__indicator-separator {
        display: none;
      }

      svg {
        display: none;
      }

      &:after {
        content: "";
        border: solid var(--gray-100);
        border-width: 0 0.0625rem 0.0625rem 0;
        display: inline-block;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        position: relative;
        padding: 0.25rem;
        margin: -0.1875rem 0 0 0.5rem;
      }
    }
  }

  // .select {
  //     max-width: 32.5rem;

  //     svg {
  //         color: var(--gray-100);
  //     }

  //     .css-1insrsq-control {
  //         /*disabled state*/
  //         background-color: var(--gray-600);

  //         .css-109onse-indicatorSeparator {
  //             display: none;
  //         }

  //         .css-1hb7zxy-IndicatorsContainer {
  //             &:after {
  //                 border-color: var(--gray-300);
  //             }
  //         }

  //         .css-jzldcf-Input {
  //             margin: 0;
  //             padding: 0;
  //         }
  //     }

  //     .css-1s2u09g-control {
  //         border-radius: 0.375rem;
  //         border-color: var(--gray-400);
  //     }

  //     .css-1pahdxg-control {
  //         border-color: var(--primary-200);
  //         box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.08) !important;
  //     }

  //     .css-319lph-ValueContainer {
  //         // padding: 0.75rem 1rem;

  //         // border-color: var(--gray-400);

  //         .css-qc6sy-singleValue,
  //         .css-lr9fot-singleValue {
  //             padding: 0.75rem 1rem;
  //         }

  //         .css-14el2xx-placeholder {
  //             margin: 0;
  //         }

  //         .css-6j8wv5-Input {
  //             margin: 0;
  //             padding: 0;
  //         }

  //         .css-6j8wv5-Input {
  //             font-size: 1rem;
  //             line-height: 1.25rem;
  //         }
  //     }

  //     .css-1okebmr-indicatorSeparator {
  //         display: none;
  //     }

  //     .css-1w9j89e-menu,
  //     .css-26l3qy-menu {
  //         box-shadow: 0 0.375rem 0.5rem rgba(0, 0, 0, 0.04);
  //         border: 0.0625rem solid var(--gray-500);
  //         z-index: 99;
  //         margin: 0.0625rem 0;

  //         .css-yt9ioa-option {
  //             font-size: 0.875rem;
  //             line-height: 1.25rem;
  //             padding: 0.75rem 1rem;
  //         }

  //         .css-1n7v3ny-option,
  //         .css-9gakcf-option {
  //             background: var(--gray-600);
  //             font-size: 0.875rem;
  //             line-height: 1.25rem;
  //             padding: 0.75rem 1rem;
  //         }

  //         .css-9gakcf-option {
  //             font-weight: var(--weight-medium);
  //             color: var(--gray-100);
  //         }
  //     }

  //     /*indicators*/
  //     .css-1hb7zxy-IndicatorsContainer {
  //         .css-tlfecz-indicatorContainer,
  //         .css-1gtu0rj-indicatorContainer {
  //             padding: 0;

  //             svg {
  //                 display: none;
  //             }
  //         }

  //         &:after {
  //             content: "";
  //             border: solid var(--gray-100);
  //             border-width: 0 0.0625rem 0.0625rem 0;
  //             display: inline-block;
  //             transform: rotate(45deg);
  //             -webkit-transform: rotate(45deg);
  //             position: relative;
  //             padding: 0.25rem;
  //             margin: -0.1875rem 0.8125rem 0 0.5rem;
  //         }
  //     }
  // }
}

/*common radio*/

.customRadio {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: nowrap;
  cursor: pointer;

  .customRadioCheck {
    width: 1rem;
    height: 1rem;
    border: 0.0625rem solid var(--gray-400);
    border-radius: 50%;
    position: relative;
    background-color: var(--base-100);
    min-width: 1rem;

    &:after {
      content: "";
      width: 0.625rem;
      height: 0.625rem;
      background-color: transparent;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    & + span {
      white-space: nowrap;
    }
  }

  // .customRadioText {
  //     margin-left: 1rem;
  //     color: var(--gray-100);
  // }

  input {
    display: none;

    &:checked {
      & ~ .customRadioCheck {
        background-color: var(--gray-100);
        border: 0.0625rem solid var(--gray-100);

        &:after {
          background-color: var(--base-100);
        }
      }
    }
  }
}

/*common switch*/
/*custom switch*/
.form-check {
  &.custom-switch {
    // display: flex;
    // align-items: center;

    input {
      // order: 2;
      float: left;
      padding: 0.755rem 0;
      width: 3.13rem;
      background-color: var(--gray-300);
      box-shadow: none !important;
      border: none !important;
      margin: 0 0.625rem;
      background-size: 1.7rem;
      border-radius: 0.9375rem;
      background-position: center left -0.09375rem;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;

      &:checked {
        background-color: var(--primary-200);
        background-position: center right -0.0625rem;
      }
    }

    .form-check-label {
      // order: 1;
      float: left;

      &.active {
        font-weight: 700 !important;
        color: var(--gray-100) !important;
      }
    }

    &:checked {
      background-position: center right -0.125rem;
    }
  }

  &.customNew-switch {
    input {
      padding: 0.8rem 0;
      width: 3rem;

      &:checked {
        background-color: var(--primary-100);
      }
    }
  }
}

.PreswitchSticky-areaEmployee {
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  background: unset;
}

.switchSticky-area {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  background: var(--base-100);

  &.Employee {
    position: unset;
    width: auto;
    background: unset;
  }

  .emp {
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 101.688%;
    color: var(--base-200);
  }

  .adm {
    font-size: 0.813rem;
    font-weight: 700;
    line-height: 101.688%;
    color: var(--base-200);
  }
}
.m-32{
  margin: 2rem !important;
}
.mb-32 {
  margin-bottom: 2rem;
}

.mt-32 {
  margin-top: 2rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.mb-22 {
  margin-bottom: 1.375rem !important;
}

.p-32 {
  padding: 2rem;
}

.pt-24 {
  padding-top: 1.5rem !important;
}

.p-20 {
  padding: 1.25rem;
}

.p-10 {
  padding: 0.625rem;
}

.py-60 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.p-40 {
  padding: 2.5rem !important;
}

.mb-12 {
  margin-bottom: 0.75rem !important;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.p-12 {
  padding: 0.75rem;
}

.pb-12 {
  padding-bottom: 0.75rem;
}

.mb-40 {
  margin-bottom: 2.5rem;
}

.pb-40 {
  padding-bottom: 2.5rem;
}

.p-52 {
  padding: 3.25rem;
}

.px-20 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.pt-20 {
  padding-top: 1.25rem;
}
.pb-20 {
  padding-bottom: 1.25rem;
}
.px-24 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-24 {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.py-32 {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.pb-32 {
  padding-bottom: 2rem;
}

.pt-32 {
  padding-top: 2rem;
}

.ms-32 {
  margin-left: 2rem;
}

.w-90 {
  width: 93%;
}

.pt-14 {
  padding-top: 0.875rem;
}

.pb-14 {
  padding-bottom: 0.875rem;
}

.mt-14 {
  margin-top: 0.875rem;
}

.mb-14 {
  margin-bottom: 0.875rem;
}
.me-14 {
  margin-right: 0.875rem;
}
.borderAll {
  border: 0.0625rem solid var(--gray-400);
}

.borderAll2 {
  border: 0.0625rem solid var(--gray-500);
}

.borderAllDashed {
  border: 0.0625rem dashed var(--secondary-400);
}

.borderBottom {
  border-bottom: 0.0625rem solid var(--gray-400);
}

.borderBottom2 {
  border-bottom: 0.0625rem solid var(--gray-500);
}

.borderLeft {
  border-left: 0.0625rem solid var(--gray-500);
}

.borderLeft2 {
  border-left: 0.0625rem solid var(--gray-400);
}

.borderRight {
  border-right: 0.0625rem solid var(--gray-400);
}

.rounded-6 {
  border-radius: 0.375rem;
}

.rounded-12 {
  border-radius: 0.75rem;
}

.boxShadow {
  box-shadow: 0 0 1.25rem rgba(40, 43, 45, 0.05);
}

.boxShadow2 {
  box-shadow: 0 0.375rem 0.5rem rgba(0, 0, 0, 0.04);
}

.boxInnerShadow {
  box-shadow: inset 0 0 0.3125rem rgba(0, 0, 0, 0.1);
}

.headImg {
  img {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
  }
}

.tableImg {
  img {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
  }
}

.btnFooter {
  // box-shadow: 0 -0.25rem 1.25rem rgba(40, 43, 45, 0.05);
  box-shadow: none;
  background-color: var(--base-100);
  padding: 1.125rem 2rem;
  border: 0.0625rem solid var(--gray-500);

  &.gap-20 {
    button.btn {
      margin-right: 1.25rem;
    }
  }

  // &.modal-footer {
  //     padding-left: 4.6875rem;
  // }
}

.yAxisCalendar {
  svg {
    top: 50%;
    transform: translateY(-50%);
  }
}

.mb-19 {
  margin-bottom: 1.188rem;
}

.p-18 {
  padding: 1.125rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

textarea {
  resize: none;
}

.breadcrumb {
  margin: 0;

  li {
    color: var(--primary-200);

    a {
      color: var(--gray-200);
    }

    & + li {
      &:before {
        content: url(../images/breadcrumbArrow.svg);
        padding: 0 0.6875rem;
      }
    }
  }
}

.modalDialog {
  .modal-dialog {
    max-width: 40rem;

    &.modal-sm {
    }

    .modal-content {
      box-shadow: 0 2.875rem 3.625rem rgba(0, 0, 0, 0.2);
      border-radius: 0.375rem;
      overflow: hidden;
      min-height: 18.375rem;

      .modal-header {
        border: 0;
        padding: 0;
        position: relative;

        .btn-close {
          position: absolute;
          top: 1rem;
          right: 1rem;
          z-index: 1;
        }
      }

      .modal-body {
        padding: 2.5rem 2.5rem 1.5rem;
      }

      .modal-footer {
        padding: 1.5rem 2.5rem;
        background-color: var(--gray-600);
        border-top: 0.0625rem solid var(--gray-400);
        min-height: 5.5rem;

        button.btn {
          &:last-child {
            margin-left: 0.75rem !important;
          }
        }
      }
    }
  }

  &.modalDialogxl {
    .modal-dialog {
    }
  }
}

.width-50 {
  width: 3.125rem;
}

.tagsInput {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  // padding-top: 0.25rem;
  border-radius: 0.375rem !important;
  padding: 0rem 0.5rem 0.49rem 0.5rem;
  font-size: 0.875rem;
  position: relative;
  max-height: 8rem;
  overflow: auto;

  &:focus,
  &:hover,
  &:visited {
    border-color: var(--primary-200);
    // box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, .08) !important;
    box-shadow: none !important;
  }

  .tagsList {
    display: contents;
  }

  form {
    width: auto;
    min-width: 3.125rem;
    flex-grow: 1;
  }

  input {
    padding: 0.25rem;
    border: 0;
    width: 100%;
    margin-top: 0.5rem;
    border-radius: 0;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.multiSelectContainer {
  ul {
    max-height: 13.563rem;

    li {
      font-size: 0.875rem;
      padding-top: 0.95rem;
      padding-bottom: 0.95rem;

      &:after {
        top: 1.22rem !important;
      }
    }
  }
}

.avgRat {
  svg {
    width: 1rem;
    height: 1rem;
  }
}

.mb-14 {
  margin-bottom: 0.875rem;
}

.pb-6 {
  padding-bottom: 0.375rem;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.mt-6 {
  margin-top: 0.375rem;
}

.mt-12 {
  margin-top: 0.75rem;
}
.mwidth-435{
  max-width: 435px;
}
.feedbackTable {
  th {
    padding: 0.7344rem 1.25rem !important;
  }

  .EmptyStateSec {
    height: calc(100% - 4.1rem);
  }
}

.iconRestrict {
  background-color: #f8f8f8;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.custom-dropdown-list {
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  border: 0.0625rem solid #ccc;
  border-radius: 0.4rem;
  max-height: 10.19rem;
  overflow: auto;
  margin-top: 0.063rem;
  padding: 0;
  z-index: 1000;

  li {
    font-size: 0.875rem;
    border-bottom: 0.0625rem solid #efefef;
    padding: 0.834rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: var(--gray-600);
    }
  }

  &.taginputHide {
    display: none;
  }

  &.taginputShow {
    display: block;
    // border-color: var(--primary-200);
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.08) !important;
  }
}

.pipeSeparator {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 0.0625rem;
    background-color: var(--gray-400);
    height: 100%;
  }
}

.tagsListItm {
  padding: 0.375rem 0.5rem;
  background-color: var(--gray-500);
  border-radius: 0.375rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  display: inline-block;
  // white-space: nowrap;

  button {
    margin-left: 0.25rem;

    svg {
      position: static;
      margin-top: 0.5rem;
    }
  }
}

.accountDropdown,
.tableDropdown {
  .dropdown-toggle {
    padding: 0;
    background: none !important;
    box-shadow: none !important;
    border: 0;

    &:after {
      display: none;
    }
  }
}

.userMedia {
  display: flex;

  .userMediaImg {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .userMediaBody {
    flex-grow: 1;
    max-width: calc(100% - 3.8rem);
    width: 100%;
  }
}

.text-underline {
  text-decoration: underline;
}

.outline-0 {
  outline: 0 !important;
}

.apexcharts-canvas {
  .apexcharts-grid {
    // display: none !important;
  }

  .apexcharts-series {
    & > path {
      &:hover {
        // fill: var(--gray-400) !important;
        // opacity: 1 !important;
        filter: none !important;
      }
    }
  }
}

ul.customSelval {
  margin: 0px;
  padding: 0px 6px;
  margin-top: 12px;
  max-height: 300px;
  overflow: auto;

  li {
    .mwidth-440 {
      width: 100%;
    }
    .status {
      font-size: 0.594rem;
      font-weight: 500;
      padding: 0.219rem 0.563rem;
      background: var(--gray-500);
      border-radius: 0.75rem;
      line-height: 0.813rem;
      align-content: center;
      &.draft {
        color: var(--gray-1200);
      }
      &.published {
        color: #2eb67d;
      }
      &.active {
        color: var(--secondary-600);
      }
    }
    .itemTitle {
      min-width: auto;
      max-width: 16rem;
    }
  }
}

.btnLink {
  font-weight: var(--weight-bold);
  color: var(--primary-200);
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0;
  border: none;

  &:hover {
    color: var(--primary-200);
  }
}

.btnNoLink {
  padding: 0;
  border: none;
}

.dropItmIcon {
  display: flex;
  align-items: center;

  svg + span {
    margin-left: 0.625rem;
  }
}

.selectDropIcon {
  .css-qc6sy-singleValue {
    .dropItmIcon {
      svg {
        display: none;
      }

      svg + span {
        margin-left: 0;
      }
    }
  }

  .css-syji7d-Group {
    &:not(:first-child) {
      border-top: 0.0625rem solid rgba($color: #d9d9d9, $alpha: 0.5);
    }
  }
}

.iconGroup {
  display: flex;
  align-items: center;
  margin-left: 0.625rem;

  span {
    svg {
      display: block;
    }
  }

  span + span {
    padding-left: 0.625rem;
    margin-left: 0.625rem;
    border-left: 0.0625rem solid var(--gray-300);
  }
}

.btnSwitch {
  padding: 0 0.625rem;

  .btnSwitchStyle {
    width: 3.125rem;
    height: 1.5rem;
    background-color: var(--gray-400);
    opacity: 0.7;
    display: block;
    border-radius: 10rem;
    position: relative;
    transition: all 0.3s;

    &:after {
      content: "";
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 501.25rem;
      background-color: var(--base-100);
      position: absolute;
      top: 0.125rem;
      left: 0.125rem;
      transition: all 0.3s;
    }
  }

  input {
    display: none;

    &:checked {
      & ~ .btnSwitchStyle {
        background-color: var(--primary-200);
        opacity: 1;

        &:after {
          left: calc(100% - 0.125rem);
          transform: translateX(-100%);
        }
      }
    }
  }
}

.bg-none {
  background: none !important;
}

.dateCalendar {
  svg {
    path {
      fill: var(--gray-300);
    }
  }

  input {
    height: 2.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--gray-100);

    &::placeholder {
      color: var(--gray-100);
    }
  }
}

a.text-primary-200 {
  cursor: pointer;

  &.disable {
    cursor: default;
  }
}

.myInput.dateCalendar {
  svg {
    top: 2.5rem;
  }

  > div {
    label {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500 !important;
      color: #232323 !important;
      margin-bottom: 0.3rem;
    }

    > .react-datepicker-wrapper {
      margin-bottom: 0.5rem;
    }
  }
}

.userSearchin {
  .myInputSearch {
    max-width: 18rem;

    input.form-control {
      padding-right: 2.4rem;
    }

    &.hoverSerch {
      input.form-control {
        padding-left: 2.5rem;
        padding-right: 0.5rem;
        word-wrap: break-word;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // width: 42px;
        background-image: url(../images/serchIco.svg);
        padding-right: 0px;
        background-repeat: no-repeat;
        background-position: center left 0.7rem;
        @include transition;
        cursor: pointer;
        // &:hover{
        //     padding-right: 0.5rem;
        //     width: auto;
        // }
      }

      svg {
        display: none;
      }
    }
  }
}

.quesMod {
  .w-100 {
    button {
      width: calc(100% - 5.5rem);

      p {
        width: 100%;
        white-space: break-spaces;
        text-align: left;
        overflow-wrap: break-word;
      }
    }
  }
}

.avgDays {
  min-width: 11rem;
}

.filters.sideFilterall {
  padding-top: 0;

  .filtersTop {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #f8f8f8;
    padding-top: 2rem;
    padding-bottom: 1rem;
    margin: 0 !important;

    &:after {
      content: "";
      position: absolute;
      width: 2rem;
      height: 5rem;
      background-color: #f8f8f8;
      right: -1.2rem;
      top: 0;
      z-index: -1;
    }
  }

  .react-datepicker {
    &__day {
      padding: 0.19rem 0.05rem;
      height: 1.2rem !important;
      width: 1.15rem !important;
      line-height: normal !important;

      &--keyboard {
        &-selected {
          background-color: var(--primary-100);
          color: var(--base-100);
        }
      }

      &--selected {
        background-color: var(--primary-200);
        color: var(--base-100);
      }
    }

    &__header {
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }

    &__navigation {
      top: 0.65rem;

      &--next {
        right: 0.15rem;
      }

      &--previous {
        left: 0.15rem;
      }

      &-icon {
        &:before {
          height: 7px;
          width: 7px;
        }
      }
    }

    &__current-month {
      padding-bottom: 0.3rem;
    }
  }
}

.filters {
  .myInputSearch {
    margin-bottom: 1rem;

    input {
      padding: 0.325rem 0.75rem;
      font-size: 0.82rem;
      line-height: 1.25rem;
    }

    svg {
      height: 1rem;
      z-index: 9;
    }
  }
}

.cp {
  cursor: pointer;
  font-size: 0.75rem;
}

.dateCalendarbgico {
  .react-datepicker-wrapper {
    input {
      background-image: url(../images/calIcon.png);
      background-repeat: no-repeat;
      background-position: center right 0.75rem;
      height: 2rem;
      font-size: 0.75rem;
      line-height: 1.25rem;
      padding: 0.4rem 0.6rem;
      padding-right: 2rem;
    }
  }

  &.iconbig {
    .react-datepicker-wrapper {
      input {
        background-image: url(../images/calIcon.svg);
        border-radius: 0.375rem !important;
        padding: 0.75rem 1rem;
        font-size: 0.875rem;
        line-height: 0.875rem;
        min-height: 2.625rem;
      }
    }
  }
}

.mySelect {
  &.paycountry {
    .selectCustom__control {
      padding: 0.7rem 1rem;
    }
  }
}

.sideFilterall {
  .dateCalendarbgico {
    .react-datepicker-popper {
      .react-datepicker__day,
      .react-datepicker__day-name {
        width: 1rem;
        height: 1rem;
        font-size: 0.75rem;
        line-height: 1.1rem;

        &--keyboard-selected {
          background-color: #f15845;
        }
      }

      .react-datepicker__navigation {
        height: 1.625rem;
        width: 0.875rem;
      }
    }
  }
}

.btnaddon {
  display: flex;
  justify-content: end;
  margin-right: 1.5rem;
  position: relative;
  padding-right: 0.5rem;

  &:after {
    content: "";
    width: 0.0625rem;
    height: 2rem;
    background-color: #d9d9d9;
    display: block;
    position: absolute;
    top: 0.4rem;
    right: 0;
  }
}

.myInputSearch {
  input.form-control {
    padding-right: 2.2rem;
  }
}

.filters.sideFilterall {
  .closefilbtn {
    span {
      &.iconfilarrow {
        position: absolute;
        left: 0;
        background-color: #f8f8f8;
        opacity: 1;
      }
    }
  }

  &.hideContent {
    .closefilbtn {
      span {
        &.iconfilarrow {
          position: absolute;
          left: 0;
          background-color: #f8f8f8;
          opacity: 0;
        }
      }
    }
  }
}

.crmodContainer {
  width: 100%;

  .crmodCon {
    button.btn {
      width: 100%;
      display: block;

      div.d-flex {
        display: block;
        width: 100%;
        white-space: break-spaces;
        text-align: left;
        padding-right: 1rem;
      }
    }
  }
}

.moduleTitle {
  p {
    &.font-medium {
      line-height: normal;
    }
  }
}

.createModulePage {
  .myInput {
    &.estmateTimein {
      input {
        border-radius: 0.375rem !important;
        padding: 0.7rem 1rem;
        font-size: 0.875rem;
      }
    }
  }

  .mySelect {
    &.estmateTimein {
      .select {
        > div {
          border-radius: 0.375rem !important;
          padding: 0.3rem 0rem;
          font-size: 1rem;
        }
      }
    }
  }
}

.manageTeamsNav {
  .manageTeamsNavLinks {
    a {
      &:nth-child(1n) {
        span.linkRing.border-secondary-300 {
          border-color: #2eb67d !important;
        }
      }

      &:nth-child(2n) {
        span.linkRing.border-secondary-300 {
          border-color: #f15845 !important;
        }
      }

      &:nth-child(3n) {
        span.linkRing.border-secondary-300 {
          border-color: #ecb22e !important;
        }
      }

      &:nth-child(4n) {
        span.linkRing.border-secondary-300 {
          border-color: #0080ff !important;
        }
      }

      &:nth-child(5n) {
        span.linkRing.border-secondary-300 {
          border-color: #e3828d !important;
        }
      }

      &:nth-child(6n) {
        span.linkRing.border-secondary-300 {
          border-color: #646464 !important;
        }
      }
    }
  }
}

.audSelect {
  > div {
    padding: 0.14rem 0rem;
  }

  .recatMySel__menu {
    z-index: 10;
    margin: 0;

    .recatMySel__menu-list {
      padding: 0;
    }
  }
}

.assignCont {
  .mw-100 {
    .myInputSearch {
      max-width: 100%;
    }
  }
}

.assignCheck {
  padding: 0 !important;

  .dlist {
    &:hover {
      background-color: var(--gray-600);
    }
  }

  .checkBox {
    .checkBoxCheck {
      top: 0.4375rem;
      background-color: #fff;
    }
  }
}

.flexDiv {
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.25rem;
  }
}

.fullSelect {
  max-width: 100% !important;
  width: 100% !important;
  display: block;
}

.uploadSizearea {
  .alowSpecf {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.recatMySel {
  &__control {
    cursor: pointer !important;
  }

  &__indicator {
    cursor: pointer;
    width: 1.75rem;
    height: 1.75rem;

    svg {
      opacity: 0;
    }

    background-image: url("../images/downIcon.png");
    background-repeat: no-repeat;
    background-position: center right 0.6875rem;
    background-size: 0.8125rem;
  }
}

.recatMySel__control {
  padding: 0.34rem;
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.recatMySel__control--is-focused {
  border-color: var(--primary-200) !important;
  box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.08) !important;
}

.recatMySel__menu {
  margin: 0.125rem 0;

  .recatMySel__menu-list {
    padding: 0;
    overflow: auto;
    max-height: 12.5rem;
    z-index: 10;
  }
}

.recatMySel__option--is-selected {
  background-color: var(--primary-200) !important;
}

.recatMySel__option--is-focused {
  background-color: var(--gray-600) !important;
  color: var(--base-200) !important;
}

.fileCheckbox {
  .container {
    padding: 0;

    label.checkBox {
      span {
        font-size: 0.875rem;
      }
    }
  }
}

.largeSearch {
  .myInput.myInputSearch {
    max-width: 24rem;
    width: 23.25rem;
    // input {
    //     padding: 0.6625rem 1rem;
    //     min-height: 3rem;
    //     line-height: normal;
    //     &::placeholder{font-size: 1rem;}
    // }
  }

  // .audSelect{
  //     >div {
  //         padding: 0.5625rem .5rem;
  //     }
  // }
  // .recatMySel{
  //     &__single-value{
  //         font-size: 1rem;
  //     }
  //     &__value{
  //         font-size: 1rem;
  //         &-container{
  //             padding-right: 2.3rem;
  //         }
  //     }
  //     &__indicator{
  //         &-separator{
  //             display: none;
  //         }
  //     }
  //     &__menu {
  //         padding: 0;
  //     }

  //     &__menu-list {
  //         font-size: 1rem;
  //     }
  // }
}

.pe-40 {
  padding-right: 2.5rem !important;
}

.otpInputs {
  input[type="text"] {
    border-radius: 0;

    &:nth-child(1),
    &:nth-child(5) {
      border-radius: 0.375rem 0 0 0.375rem;
    }

    &:last-child,
    &:nth-child(3) {
      border-radius: 0 0.375rem 0.375rem 0;
    }
  }

  .sepLine {
    min-width: 1.5rem;
    text-align: center;
    position: relative;

    &:after {
      width: 0.8rem;
      height: 0.0625rem;
      background-color: #ababab;
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
    }
  }
}

.sendCode.modal-header {
  background: transparent;
  border: none;
  padding: 0.5rem;
  position: relative;

  button.btn-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 1;

    &:focus {
      box-shadow: none;
    }
  }
}

.namePDF {
  .fullNamePDF {
    padding-left: 0.6rem;
    width: 100%;

    .h6 {
      width: 100%;
      word-wrap: break-word;
      padding-right: 3rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-letter {
        text-transform: capitalize;
      }
    }
  }

  .crossBtn {
    .btn {
      background: transparent !important;
      border: none;
      box-shadow: none;
      padding: 0 !important;
      line-height: normal !important;
      position: relative;
      top: -0.125rem;
    }
  }
}

.mySelect {
  input.form-control {
    border-radius: 0.375rem !important;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;

    &:disabled {
      background-color: var(--gray-500);
      border: var(--gray-400);
    }

    &:focus {
      border-color: var(--primary-200);
      // box-shadow: 0px 10px 20px rgba(0,0,0,.08) !important;
    }
  }
}

.fileChoos {
  .mySelect {
    input.form-control {
      font-size: 0.875rem;
      line-height: 0.75rem;
      padding: 0.89rem 0.899rem;
    }
  }
}

.createPage {
  .modal-footer {
    box-shadow: 0 -0.25rem 1.25rem rgba(40, 43, 45, 0.05);
    background-color: var(--base-100);
    padding: 0.875rem 2rem;
    border: 0.0625rem solid var(--gray-500);
  }
}

.myInput {
  span.verifiedTag {
    display: none;
  }
}

.verifyedInput {
  position: relative;

  span.verifiedTag {
    display: inline-block;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #2eb67d;
    font-weight: 600;
    top: 2.35rem;
    right: 0.7rem;
    z-index: 9;

    svg {
      position: initial !important;
      transform: inherit !important;
      margin-right: 0.3rem;
    }
  }
}

.simpleCheck {
  input.checkBoxCheck {
    float: left;
    position: relative;
    top: 4px;
    margin-right: 0.4rem;
  }
}

.textEclipsis {
  text-overflow: ellipsis;
  width: 100%;
  max-width: 15rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

.myInput.phoneCountry {
  .PhoneInputCountry {
    border: none;
    margin: 0;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem 0 0 0.375rem;
    border-right: 0;
    position: absolute;
    height: 2.625rem;
    border-right: 0.1rem solid #eee;
    top: 0.1rem;

    .PhoneInput {
      position: relative;

      input.PhoneInputInput {
        padding-left: 5rem;
      }
    }

    .PhoneInputCountryIconImg {
      position: inherit;
      transform: inherit;
    }
  }
}

.headerFirstTitle {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 12.5rem;
}

.EmptyStateSec {
  width: 100%;
  height: calc(100% - 4rem);
  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 14rem;

  .EmptyGraphics {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.PhoneInput {
  position: relative;

  input.PhoneInputInput {
    padding-left: 5rem;
    display: block;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 0.875rem;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625rem solid #ced4da;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus-visible {
      border-color: var(--primary-200) !important;
      // box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, .08) !important;
      box-shadow: none !important;
      outline: none;
    }
  }

  &--focus {
    input.PhoneInputInput {
      border-color: var(--primary-200) !important;
      // box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, .08) !important;
      box-shadow: none !important;
      outline: none;
    }
  }
}

.SupportIconBtn {
  > a,
  > .btn.p-o,
  button.border-0.p-0.btn {
    margin: 0 0.5rem;
    display: inline-block;
  }
}

.mx-20 {
  margin: 0 1.25rem !important;
}

.ps-10 {
  padding-left: 0.625rem;
}

.SupportIconBtn {
  > a .btn {
    margin: 0 !important;
  }
}

.ml-auro {
  margin-left: auto;
  margin-right: 0 !important;
}

.passDisable {
  .passInput {
    pointer-events: none;
  }
}

.updatePassword {
  .input-group.myInput {
    max-width: 100%;
  }
}

.cusWidthpop {
  min-width: 20rem;

  .popMoresec {
    padding: 1rem 0;
    border-bottom: 0.0625rem solid #efefef;

    &:last-child {
      border: none;
    }
  }
}

.selectBox {
  .selectCustom__control {
    border-radius: 1rem;
  }
}

.mulSelCustom {
  .search-wrapper.searchWrapper {
    border-radius: 0.375rem !important;
    padding: 0.5rem 0.5rem;
    font-size: 0.875rem;
    background-color: var(--base-100);
    border: 0.0625rem solid var(--gray-400);
    background-image: none;

    span.chip {
      background-color: var(--primary-200);
      // max-width: 9.375rem;
      // overflow: hidden;
      // text-overflow: ellipsis;
      max-width: 13.5rem;
      word-break: break-word;
      white-space: inherit;
    }

    input#search_input {
      border: none;
      padding: 0.2rem;
      min-width: auto;
      max-width: inherit;
      width: auto !important;
      margin: 0 !important;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: rgb(51 51 51);
      }
    }

    &:after {
      content: "";
      border: solid var(--gray-100);
      border-width: 0 1px 1px 0;
      display: inline-block;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      position: absolute;
      padding: 0.25rem;
      margin: -0.1875rem 0 0 0.5rem;
      right: 16px;
      top: 16px;
    }
  }

  .optionListContainer {
    ul.optionContainer {
      li {
        position: relative;
        padding-left: 2rem;

        &:after {
          content: "";
          border: 0.0625rem solid var(--gray-400);
          border-radius: 0.25rem;
          display: inline-block;
          width: 1rem;
          height: 1rem;
          vertical-align: top;
          position: absolute;
          left: 0.5rem;
          top: 0.9rem;
          background-repeat: no-repeat;
          background-position: center;
          background-color: #fff;
          @include transition;
        }

        &:hover {
          background-color: var(--gray-600);
          color: var(--gray-100);
        }

        input[type="checkbox"] {
          display: none;
        }

        &.selected {
          background-color: #f8f8f8;

          &:hover {
            background-color: var(--gray-600);
            color: var(--gray-100);
          }

          &:before {
            content: "";
            background-color: var(--primary-200);
            border: 0.0625rem solid var(--primary-200);
            background-image: url(../images/check_white.svg);
            position: absolute;
            width: 1rem;
            height: 1rem;
            left: 0.5rem;
            top: 0.9rem;
            z-index: 1;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 0.25rem;
          }
        }
      }
    }
  }
}

.formView {
  .input-group.myInput {
    label.checkBox {
      width: auto;

      span.checkBoxCheck {
        margin-top: 0.125rem;
      }

      span {
        text-transform: capitalize;
      }
    }

    .radioButton {
      width: auto;

      span.radioCheck {
        margin-top: -0.1rem;
      }

      span {
        text-transform: capitalize;
      }
    }
  }
}

.dropValue {
  .mySelect {
    max-width: 100%;

    .select {
      max-width: 100%;
    }
  }

  .selDropdown {
    &__control {
      padding: 0.34rem;
      box-shadow: none !important;
      border-color: #ced4da !important;
      position: relative;
    }

    &__indicators {
      position: relative;

      svg {
        position: initial;
        right: inherit;
        top: inherit;
        transform: initial;
      }
    }

    &__option--is-selected {
      background-color: var(--primary-200) !important;
    }

    &__option--is-focused {
      background-color: var(--gray-600) !important;
      color: var(--base-200) !important;
    }
  }
}

.calpr {
  .yAxisCalendar {
    svg {
      z-index: 0;
    }

    .react-datepicker-wrapper {
      input.form-control {
        background: transparent;
      }
    }
  }
}

.fullDiv {
  width: calc(100% - 4.6875rem);

  p {
    span.tableTag {
      display: inline;
      margin-left: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}

.count {
  span.roundCount {
    width: 2rem;
    height: 2rem;
    background-color: var(--gray-400);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: var(--gray-100);
  }
}

.mb-10 {
  margin-bottom: 0.625rem !important;
}

.catalogSidebar {
  .catlogHeading {
    opacity: 0.6;

    &.active {
      opacity: 1;
    }
  }

  .collapsed {
    .catlogHeading {
      opacity: 0.6;

      &.active {
        opacity: 1;
      }

      .tableTag {
        display: none;
      }
    }
  }

  h4 {
    color: var(--gray-100);
  }
}

.paginationMax {
  padding: 0.8rem 1.5rem;
  border-top: 0.0625rem solid rgb(204 204 204 / 18%);

  .pBtn {
    width: 2.4rem;
    padding: 0.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    margin-left: 0.3rem;
    border: 0.0625rem solid #efefef;
    font-weight: normal;

    &:hover,
    &.active {
      background-color: #f15845;
      color: #fff;
      border-color: #f15845;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}

.IconInput {
  .cardTypeImg {
    position: absolute;
    z-index: 9;
    top: 0.75rem;
    left: 0.4375rem;
  }
}

.catlogAccordion {
  .accordion-body {
    padding: 1.25rem 0.25rem 0.25rem !important;
  }

  .accordion-item {
    background-color: transparent !important;

    .accordion-header {
      .accordion-button {
        background-color: transparent !important;
        border: none !important;
      }
    }

    &:after {
      display: none;
    }

    .acContent {
      white-space: break-spaces;
    }
  }
}

.assignmentTitle {
  word-wrap: break-word;
}

.h-83 {
  min-height: 5.2rem;
  display: flex;
  align-items: center;
}

.row.col5c {
  .col {
    flex: 0 0 auto;
    width: 20%;
  }
}

span.totalPagesPagination {
  display: flex;
  align-items: center;
  margin-right: 0.4rem;

  font {
    display: block;
    padding: 0 0.3rem;
    font-weight: bold;
  }
}

.delModal {
  position: relative;

  &.show {
    position: absolute;
    background-color: rgb(0 0 0 / 50%);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

button.fSubmitMod.btn {
  background: transparent;
  padding: 0;
  border: none;
  box-shadow: none;
  margin-left: 0.5rem;
  position: relative;
  top: -0.125rem;
}

.pupdatepass {
  .input-group.myInput {
    span {
      svg {
        top: 1.55rem;
      }
    }
  }
}

.modal-body {
  .card.cardMod {
    border: 0.0625rem solid var(--gray-400);
    box-shadow: 0 0 1.25rem rgba(40, 43, 45, 0.05);
    border-radius: 0.375rem;
    overflow: hidden;
    flex-grow: 1;
    position: relative;
    overflow-y: auto;
  }
}

.lineH {
  line-height: normal;
}

.acBillingNav {
  padding-top: 1.25rem;
}

.joinPopInfo {
  .myInput.myInputSearch {
    max-width: 100%;
    width: 100%;

    .form-control {
      font-size: 0.875rem;
      line-height: 0.875rem;
      line-height: normal;
      height: auto;
      padding: 0.719rem 0.75rem;

      &::placeholder {
        font-size: 0.875rem;
      }
    }
  }

  .selectBox {
    margin: 0px !important;
  }

  .recatMySel {
    &__control {
      padding: 0.5623rem;
    }

    &__indicator {
      &-separator {
        display: none;
      }
    }
  }
}

.onEmpName {
  max-width: 11rem;
}

.labelField {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: var(--weight-medium);
  display: block;
  margin-bottom: 8px;
  width: 100%;
}

.singleLineEc {
  width: 100%;
  word-wrap: break-word;
  padding-right: 3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 49px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

//   .ec2line {
//     display: block;
//     display: -webkit-box;
//     max-width: 100%;
//     height: 34px;
//     margin: 0 auto;
//     font-size: 14px;
//     line-height: 1;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
.conteHeading {
  svg {
    min-width: 1.25rem;
  }
}

.contect {
  .pages {
    .pageDashboard {
      .page {
        overflow: hidden;
        flex-grow: 1;
        overflow-y: auto;
      }
    }
  }
}

.tableImg {
  height: 2rem;
  width: 2rem;
}

.postSlider {
  position: relative;

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    display: flex;
    flex-direction: column;

    // flex-grow: 1;
    & > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  .slick-arrow {
    position: absolute;
    top: calc(50% - 1.5rem);
    color: transparent;
    background-repeat: no-repeat;
    background-position: center left;

    &.slick-prev {
      left: -0.75rem;
      background-image: url(../images/leftSlide.png);
    }

    &.slick-next {
      right: -0.75rem;
      background-image: url(../images/rightSlide.png);
      background-position: center right;
    }

    &.slick-disabled {
      opacity: 0.6;
    }
  }
}

.assignStatusbar {
  align-items: center;
  margin-bottom: 1rem;

  .assignStatusp {
    min-width: 4.125rem;
    margin-right: 1rem;
    padding: 0;
    margin-bottom: 0;
    text-align: right;
  }

  h3 {
    padding-left: 0.5rem;
    font-size: 1.125rem;
  }
}

.yourJourny {
  .page {
    height: 100%;

    .EmptyDatadiv {
      height: calc(93% - 90.5rem);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .em100 {
      .EmptyDatadiv {
        height: 100%;
      }
    }
  }
}

.MyLearning {
  .page {
    .EmptyDatadiv {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &.emhead {
    .page {
      .EmptyDatadiv {
        height: calc(100% - 60px);
      }
    }
  }

  &:hover,
  &.active {
    background-color: var(--gray-600);
  }
}

.formActionList {
  button {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1rem 1.25rem;
    margin-top: 0.625rem;

    & > div {
      display: flex;
      flex-grow: 1;
      align-items: center;
      max-width: calc(100% - 1.25rem);

      .formActionIn {
        display: inline-block;
        padding-right: 1rem;
        max-width: calc(100% - 1.5rem);

        h6,
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &:hover,
    &.active {
      background-color: var(--gray-600);
    }
  }
}

.formContentList {
  padding: 2rem 2.5rem 4rem;
  flex-grow: 1;
  overflow: auto;
  position: relative;

  .formContentListItm {
    display: none;

    label {
      color: var(--gray-300);
      display: block;
      margin-bottom: 0.625rem;
    }

    ol {
      li {
        &::marker {
          color: var(--gray-300);
        }

        &:not(:last-child) {
          margin-bottom: 1.875rem;
        }
      }
    }

    &.active {
      display: block;
    }
  }

  .ParentdownloadFile {
    margin-bottom: 0.75rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .downloadFile {
    display: flex;
    align-items: center;
    color: var(--gray-100);
    max-width: 100%;

    span {
      padding: 0 1rem 0 0.625rem;
      flex-grow: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 3.125rem);
    }
  }
}

.iconDownload {
  path {
    fill: var(--primary-200);
  }
}

.formAttachments {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--base-100);
  padding: 1.375rem 1.875rem 0;
  filter: drop-shadow(0 -0.25rem 1.25rem rgba(40, 43, 45, 0.05));

  .attachmentsCards {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s;

    &.active {
      max-height: 12.5rem;
      overflow: auto;
    }
  }

  .attachmentsCardsRow {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-bottom: 1.375rem;
  }

  .attachmentsCard {
    position: relative;
    padding: 0.375rem 0.375rem 1.5rem;
    border-radius: 0.375rem;
    background-color: #e8e8e8;
    width: 11.875rem;

    img {
      width: 100%;
      display: block;
      border-radius: 0.375rem;
      height: 105px;
    }

    button {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #e8e8e8;
      padding: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 0.625rem;

      span {
        padding: 0 0.5rem;
        white-space: nowrap;
        max-width: calc(100% - 3rem);
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        width: 1rem;
        height: auto;
      }
    }

    .imageBox {
      position: relative;

      .playIcon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.openCardBtn {
  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.text-primary-200 {
  a {
    color: var(--primary-200);
  }
}

.btnArrow {
  button {
    &.btn {
      border: none;
      background: transparent !important;
      box-shadow: none !important;
      padding: 0.5rem;

      svg {
        width: 0.413rem;
        height: 0.831rem;
      }
    }

    &.disable {
      opacity: 0.2;
    }
  }
}

// multiSelectMenuTop

.multiSelectMenuTop {
  .optionListContainer {
    top: auto;
    bottom: 100%;
    margin-bottom: 0.063rem;
    z-index: 10;
  }
}

// end multiSelectMenuTop
.mx19rem {
  max-width: 19rem;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  padding: 0px !important;
}

.ec4line {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
  padding: 0px !important;
}

.ec2line {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  padding: 0px !important;
  /*

    display: -webkit-box;
    max-width: 100%;
    height: 34px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
*/
}

//
.blockdiv {
  .text-truncate {
    max-width: 12.5rem;
  }
}

.noShadow {
  box-shadow: none !important;
}

.aspect-ratio-1 {
  aspect-ratio: 1 / 1;
}

.word-break {
  word-break: break-word;
}

.flex1half {
  max-width: 65%;
}

.flex2half {
  max-width: 40%;
}

.mx-12 {
  margin: 0 0.75rem;
}

.ps-20 {
  padding-left: 1.25rem !important;
}

.mt-29 {
  margin-top: 1.813rem;
}

.px-44 {
  padding-left: 2.75rem !important;
  padding-right: 2.75rem !important;
}

.p-32 {
  padding: 2rem !important;
}

.ms-12 {
  margin-left: 0.75rem;
}

.py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.p20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
  padding-right: 1.25rem !important;
}

textarea.form-control {
  min-height: calc(6.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}

// .stickyTop {
//     position: sticky;
//     top: 0;
//     z-index: 1;
// }

.ps-8 {
  padding-left: 5rem !important;
}

.px-8 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.px-20 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.btn-0 {
  button.accordion-button {
    padding: 0px !important;
  }
}

.px-40 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.navTeam {
  .authoringNav {
    li {
      a {
        font-size: 0.75rem;
        padding-bottom: 0.75rem;
      }
    }
  }
}

.EmptyDatadiv {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.eptyFlex {
  .EmptyDatadiv {
    padding: 3.685rem 0;
  }
}

.radius-6 {
  border-radius: 0.375rem;
}

.boxIcon {
  padding: 0.688rem 0.563rem;

  svg {
    min-width: 1.5rem;
  }
}

.fullHeight {
  .tab-content {
    height: 100% !important;
  }
}

.oopsScreen {
  background-image: url(../images/login_bg_2.svg);
  background-color: var(--pastel-100);
  padding: 1.5rem 0;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: left bottom;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .chat-button {
    position: absolute;
    right: 0.5rem;
    bottom: 0;
  }
}

.removeFile {
  span {
    svg {
      position: absolute;
      right: 0.625rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
    }
  }
}

.btnArrow {
  .pagination-no {
    gap: 5px;

    a {
      background: var(--gray-500);
      font-size: 0.813rem;
      color: var(--gray-100);
      font-weight: 400;
      height: 2.063rem;
      width: 2.063rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem;

      &.active {
        background: var(--primary-100);
        color: var(--base-100);
      }

      &.dot {
        background: transparent;
      }
    }
  }
}

.new-pagination {
  .text-size {
    font-size: 0.813rem;
  }

  .btnArrow {
    button {
      svg {
        height: 1rem;
        width: 1rem;
      }

      &:disabled {
        cursor: default;

        svg {
          path {
            fill: var(--gray-300);
          }
        }
      }
    }
  }
}

.spinArea {
  // position: absolute;
  // right: -1.875rem;
  // bottom: 0;
}

.animateClass {
  &.pageSpin {
    position: absolute;
    right: -1.875rem;
    top: 0.6rem;
  }

  &.download {
    position: absolute;
    left: 12.438rem;
    top: 0;
  }

  &.uploadFileSpin {
    position: absolute;
    right: -2.25rem;
    bottom: 8rem;
    top: auto;
  }

  margin-left: 0.5rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    animation: rotate-animation 1s linear infinite;
  }
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tickfill {
  position: absolute;
  right: -1.25rem;
  top: -0.25rem;
}

.createPageTick {
  position: absolute;
  right: -1.875rem;
  top: 2.5rem;
}

.downloadTickFill {
  position: absolute;
  width: auto;
  right: -1.875rem;
  top: 0.85rem;
}

.uploadPageTickFill {
  position: absolute;
  right: -2.25rem;
  bottom: 4rem;
}

.mySelect {
  &.newMySelect {
    .selectCustom__control {
      padding: 0rem 1rem !important;
    }
  }
}

.formLoader {
  height: calc(100vh - 140px);
}

.downgradeNotPossibleModal {
  .modal-header {
    .btn-close {
      right: 1.5rem !important;
      top: 1.5rem !important;
      font-size: 0.875rem !important;
    }
  }

  .modal-dialog {
    max-width: 53.75rem !important;
  }

  .modal-body {
    padding: 2rem 2.25rem 0 2.25rem !important;
  }
}

.employeeActivityClose {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.journyCardshadow {
  &.employeejournyCardshadow {
    border-top: 0 !important;
    border-bottom: 1px solid #f2f2f5 !important;
    padding-bottom: 2rem;
    margin-bottom: 0 !important;
  }
}

.downgradePlanModal {
  .modal-dialog {
    max-width: 53.75rem !important;

    .modal-header {
      .btn-close {
        right: 1.5rem !important;
        top: 1.5rem !important;
      }
    }

    .modal-body {
      padding: 2rem 2.5rem !important;

      .usagePlanBox {
        .first {
          width: 40%;
        }

        .second {
          width: 30%;
          background: var(--pastel-100);
        }

        .third {
          width: 30%;
        }
      }
    }

    .modal-footer {
      padding: 1.25rem 2.25rem !important;
    }
  }

  .w-605 {
    width: 37.813rem;
  }
}

.pt-10 {
  padding-top: 0.625rem !important;
}

.px-12 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.py-12 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.mt-40 {
  margin-top: 2.5rem;
}

.mtm-32 {
  margin-top: -2rem;
}

.w-472 {
  width: 29.5rem !important;
}

.document-preview {
  #header-bar {
    display: none;
  }

  .pdf-canvas {
    display: flex;
    justify-content: center;
  }
}

.contect-doc {
  padding-left: 0 !important;
}

.inputInlineEdit {
  padding: 0;
  border: none;
  border-bottom: 1px dashed #888;

  &:focus {
    outline: 0;
    border-bottom: 1px dashed #4a154b;
  }
}

.arrow-containerIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.188rem;

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-bottom: 0.25rem solid #888;

    &.active {
      border-bottom: 0.25rem solid #232323;
    }
  }

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-top: 0.25rem solid #888;

    &.active {
      border-top: 0.25rem solid #232323;
    }
  }
}
.customRightToolTip {
  position: relative;
  display: inline-block;
  // z-index: 9;
  cursor: default;
  width: 100%;
  flex: 1;
  .tooltiptext {
    visibility: hidden;
    max-width: 12rem;
    min-width: 5rem;
    background-color: #fff;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    color: #888;
    text-align: left;
    border-radius: 0.375rem;
    padding: 0.5rem 0.5rem;
    position: absolute;
    z-index: 1;
    top: -0.85rem;
    left: 1.875rem;
    filter: drop-shadow(0 0.5rem 1.5rem rgba(0, 0, 0, 0.12));
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -0.625rem;
      border-width: 0.625rem;
      border-style: solid;
      border-color: transparent #fff transparent transparent;
    }
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}
.dataItem {
  &.customWidth {
    min-width: 0rem !important;
  }
}

.templateCard {
  &.card {
    border-radius: 0.75rem;
    border: 0.063rem solid #efefef;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.04);
  }
}

.teamDetailsOpenFilter {
  width: 16.875rem;
  height: 100%;
  background: #f8f8f8;
  position: absolute;
  right: 0;
  top: 0;
  padding: 2rem 1.5rem;
  z-index: 10;
}
.pptPreview {
  #react-doc-viewer {
    width: 100%;
    border: 0;
    padding: 0;
    margin: 0;
    &:focus-visible {
      border: 0;
      outline: none;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 1px;
      right: 4px;
      background: #444444;
      height: 22px;
      width: 100px;
      z-index: 9;
    }
  }
  #proxy-renderer {
    overflow: hidden;
    border: 0;
    padding: 0;
    margin: 0;
    &:focus-visible {
      border: 0;
      outline: none;
    }
  }
  #msdoc-renderer {
    overflow: hidden;
    border: 0;
    padding: 0;
    margin: 0;
    &:focus-visible {
      border: 0;
      outline: none;
    }
  }
}
.cursor-pointer{
    cursor: pointer !important;
}
.mySelectPageType{
  .css-1dimb5e-singleValue{
    overflow: unset !important;
  }
}
.headerDropdownAccount{
  .accountDropdown{
    .dropdown-menu{
      z-index: 9999;
    }
  }
}

.css-1dimb5e-singleValue{
  overflow: unset !important;
}
.dateCalendar .react-datepicker-popper{
  z-index: 9;
}