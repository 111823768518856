.ftue {
    .ftueOver {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.4);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
    }
    .ftuePoint {
        position: relative;
        z-index: 1001;
        outline: 0.5rem solid var(--base-100);
    }
}