.createModulePage {
    .myInput {
        max-width: 27.5rem;
    }
}

.mwidth-440 {
    width: 27.5rem;
}

.mwidth-540 {
    width: 33.75rem;
}

.mwidth-268 {
    width: 16.75rem;
}





.crossBtn {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.headImg {

    &.moduleImg {
        position: relative;

        &:nth-child(2) {
            left: -0.625rem;
            z-index: 1;
        }

        &:nth-child(3) {
            left: -1.25rem;
            z-index: 2;
        }

        &:nth-child(4) {
            left: -1.875rem;
            z-index: 3;
        }

        &.imgCount {

            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            border: 0.25rem solid var(--gray-600);
            background: var(--gray-500);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;

            .total {

                font-weight: var(--weight-bold);
                color: var(--primary-100);


            }
        }

        img {
            border: 0.25rem solid var(--gray-600);
        }

    }
}


// .detailModulePage {

.feedbackData {
    .dataItem {
        min-width: 12.5rem;

        &:not(:last-child) {
            padding-right: 2rem;
            margin-right: 2rem;
            border-right: 0.0625rem solid var(--gray-400);



        }
        .tableName {
  
            min-width: 2.5rem;
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            border: 0.25rem solid #ffffff;
            background: var(--gray-500);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
        }
        .headImg.moduleImg.imgCount{
            border: 0.25rem solid #ffffff;
        }
    }

    &.users {

        .dataItem {
            min-width: auto;

            &:first-child {
                max-width: 7.5rem;
            }

            &:not(:last-child) {
                padding-right: 1.25rem;
                margin-right: 1.25rem;

            }
            &.userAllToatal{
                &:first-child {
                    max-width: inherit;
                }
            }

        }

    }
}

// }
.topNavHeader{
    padding-top: 0.21875rem;
    li{
        margin-right: 1.25rem;
        font-size: .875rem;
        line-height: 1.25rem;
        color: var(--gray-100);
        position: relative;
        &.active{
            font-weight: 700;
            &:after {
                position: absolute;
                width: 100%;
                height: 0.125rem;
                content: "";
                background: #f15845;
                bottom: -1.5rem;
                left: 0;
            }
            a{
                font-weight: 700;
            }
        }
        &:hover{
            &:after {
                position: absolute;
                width: 100%;
                height: 0.125rem;
                content: "";
                background: #f15845;
                bottom: -1.5rem;
                left: 0;
            }
        }
        a{
            position: relative;
            font-size: .875rem;
            line-height: 1.25rem;
            color: var(--gray-100);
            
        }
    }
    &.docNav {
        li{
            &.active{
                &:after {
                    bottom: -1.8125rem;
                }
            }
            &:hover{
                &:after {
                    bottom: -1.8125rem;
                }
            }
        }
    }
}
.headImg.moduleImg.imgCount{
    position: relative;
    z-index: 1;
}
.cardContent {

    box-shadow: 0 0.375rem 0.5rem rgba(0, 0, 0, 0.04) !important;
    border: 0.0625rem solid var(--gray-500);
    border-radius: 0.375rem;
    background: var(--base-100) !important;
    position: relative;
    margin-bottom: 1.25rem;
    padding: 0;


    .count {
        min-width: 2.15rem;
        height: 2.25rem;
        width: 2.25rem;
        border: 0.0625rem solid var(--gray-400);
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        
        justify-content: center;



        span {
            border-radius: 50%;
            background: var(--primary-200);
            width: 1.25rem;
            height: 1.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--base-100);
            border: 0.0625rem solid var(--primary-200);


            &.noBgBorderPurple {
                background: transparent;
                border-color: var(--primary-200);
                color: var(--primary-200);

            }

            &.noBgBorderGray {
                background: transparent;
                border-color: var(--gray-400);
                color: var(--gray-200);

            }
        }
        &.countRound{
            border: none;
            width: auto;
            height: auto;
            align-items: flex-start;
            span.roundCount{
                width: 2rem;
                height: 2rem;
                background-color: #efefef;
                border: none;
                color: #232323;
                font-size: 1rem;
                margin-top: 0.2rem;
            }
        }

    }

    .issuesNo {
        height: 2.25rem;
        width: 2.25rem;
        border-radius: 50%;
        background-color: var(--gray-500);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: var(--weight-medium);
    }

}
.subAutNav{
    .border-right {
        position: relative;
        &:after{
            position: absolute;
            content: "";
            width: 0.0625rem;
            height: 1.25rem;
            right: 0;
            top: 0;
            display: block;
            background-color: #D9D9D9;
        }
    }
}
.bIcon {
    padding-bottom: 0.3125rem !important;
    span.me-1 {
        position: relative;
        top: -0.0625rem;
    }
    &.active{
        &:after{
            width: 1.25rem;
            height: 0.1875rem !important;
            border-radius: 0.5rem;
        }
    }
}
.cardBody {
    padding-left: 2.6875rem;
    padding-right: 2.1875rem;

    .count {
        min-width: 1.5rem;
        height: 1.5rem;
        width: 1.5rem;
        border: 0.0625rem solid var(--gray-400);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &.borderPurple {
            border-color: var(--primary-200);
            color: var(--primary-200);
        }

        &.bgPurple {
            border-color: var(--primary-200);
            color: var(--base-100);
            background-color: var(--primary-200);
        }
        &.borderGray{
            border-color: var(--gray-400);
        }
    }

    &:not(:last-child) {
        .count {

            &.borderPurple,
            &.bgPurple {
                &:before {
                    background-color: var(--primary-200);
                    height: 2.325rem;
                    position: absolute;
                    content: '';
                    width: 0.0625rem;
                    left: 50%;
                    top: 1.375rem;
                    transform: translateX(-50%);
                    z-index: 99;

                }
            }

            &:after {
                position: absolute;
                content: '';
                width: 0.0625rem;
                height: 2.325rem;
                background-color: var(--gray-400);
                left: 50%;
                top: 1.375rem;
                transform: translateX(-50%);
                z-index: 9;


            }
        }

    }
    &.noPoint{
        .count{
            &:after{
                display: none;
            }
        }
    }
}

.myCatalog{
    opacity: .5;
    .count{
        &:after{
            display: none;
        }
        &:before{
            display:none;
        }
    }
    &.active{
        opacity: 1;
        .countLine{
            position: relative;
            &:after{
                position: absolute;
                content: "";
                width: 0.0625rem;
                height: calc(100% - 0.1875rem);
                background-color: var(--gray-400);
                left: 0.75rem;
                top: 1.5rem;
                transform: translateX(-50%);
               
            }
            &.active{
                &:before{
                    position: absolute;
                    content: "";
                    width: 0.0625rem;
                    height: calc(100% - 0.1875rem);
                    background-color: var(--primary-200);
                    left: 0.75rem;
                    top: 1.5rem;
                    transform: translateX(-50%);
                    z-index: 1;
                   
                }
            }
        }
    }
}


.templateList {
    .modal-content {
        background-color: var(--gray-600);
    }
}
.btn-Deleteico {
    background: transparent;
    border: none;
    position: relative;
    z-index: 2;
    &:hover{
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
        svg{
            path{
                stroke: var(--primary-100);
            }
        }
    }
}
.templateList {
    .filtersContent {
        .card {
            position: relative;
            border: 0.0625rem solid var(--gray-shades-gray-20, #EFEFEF);
            box-shadow: 0 0.375rem 0.5rem 0 rgba(0, 0, 0, 0.04);
            background-color: var(--base-100);
            .temBtn {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: calc(100% - 2.5rem);
                justify-content: center;
                align-items: center !important;
                background-color: rgb(255 255 255 / 42%);
            }
            &:hover{
                .temBtn {
                    opacity: 1;
                    transition: opacity 0.3s;
                }
            }
        }
    }
   .sideFilterall {
    .filtersAccordian {
        padding: 1rem 0;
        margin: 1rem 0;
       &:not(:last-child){
        border-bottom: 0.0625rem solid (var(--gray-400));
       }
    }
   }
}

.h-head{
    height: calc(100% - 5.063rem) !important;
}

.mw-90{
    max-width: 90%;
}
.w-87{
    width: 87%;
}
.libraryTemplateEmpty{
    .EmptyStateSec{
        height: 100%;
    }
}