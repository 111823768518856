$selectric-main-color: #DDD !default; // Color used in border
$selectric-secondary-color: #BBB !default; // Color used in button
$selectric-text-color: #444 !default; // Color used in label
$selectric-bg-color: #F8F8F8 !default; // Background color
$selectric-btn-bg-color: #F8f8f8 !default; // Button background color
$selectric-height: 40px !default; // Outer height
$selectric-spacing: 10px !default; // Label left padding
$selectric-border-width: 1px !default; // Outer border width
$selectric-border-radius: 3px !default; // Border radius
$selectric-inner-height: 1.5em !default; // Inner height
$selectric-font-size: 1rem !default; // Font size

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  text-align: left;
  max-width: 32.5rem;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: $selectric-border-width solid var(--gray-200);
  border-radius: $selectric-border-radius;
  background: $selectric-bg-color;
  position: relative;
  overflow: hidden;
  background-color: var(--base-100);
  border: 1px solid var(--gray-400);
  border-radius: 0.375rem;

  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: $selectric-font-size;
    line-height: $selectric-inner-height;
    color: $selectric-text-color;
    height: $selectric-inner-height;
    user-select: none;
    padding: 0.75rem 3.375rem 0.75rem 1rem;
    height: auto;
    color: var(--gray-100);
  }

  .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(../images/select_arrow_down.svg);
    background-repeat: no-repeat;
    background-position: center;
    color: transparent !important;
    width: 3.375rem;
  }
}

.selectric-focus .selectric {
  // border-color: darken($selectric-main-color, 20%);
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, .059);
  border-color: var(--gray-400);
}

.selectric-hover .selectric {
  // border-color: darken($selectric-main-color, 10%);
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, .059);
  border-color: var(--gray-400);

  .button {
    color: darken($selectric-secondary-color, 10%);

    &:after {
      border-top-color: darken($selectric-secondary-color, 10%);
    }
  }
}

.selectric-open {
  z-index: 9999;

  .selectric {
    // border-color: darken($selectric-main-color, 10%);
    border-color: var(--gray-400);
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, .059);
  }

  .selectric-items {
    display: block;
    border: 1px solid var(--gray-500);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.04);


  }
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%;
  }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--base-100);
  border: 0;
  z-index: -1;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.04);
  border-radius: 6px;

  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 100%;
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: $selectric-font-size;
    line-height: 20px;
    min-height: 20px;
  }

  li {
    display: block;
    padding: 16px 20px;
    color: #232323;
    cursor: pointer;
    font-size: 14px;

    &.selected {
      background: #F8F8F8;
      color: #232323;
      font-weight: var(--weight-500);
    }

    &.highlighted {
      background: #F8F8F8;
      color: #232323;
    }

    &:hover {
      background: #F8F8F8;
      color: #232323;
    }
  }

  .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      font-weight: var(--weight-bold);
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #232323;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}