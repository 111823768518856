.manageTeamsNav {
  width: 24%;
  overflow: auto;
  background-color: var(--base-100);
  border-right: 1px solid var(--gray-400);
  padding: 1.5rem 1.25rem;

  .manageTeamsNavLinks {
    a {
      display: block;
      margin-top: 1rem;
      background-repeat: no-repeat;
      background-position: center right;
      position: relative;
      padding-left: 1.125rem;
      padding-right: 1.125rem;

      &:hover {
        color: var(--gray-100) !important;
      }

      .linkRing {
        position: absolute;
        left: 0;
        top: 0.3rem;
      }

      &.active {
        color: var(--gray-100) !important;
        font-weight: var(--weight-medium);
        background-image: url(../images/arrowRightSm.svg);
      }
    }
  }
}

.manageTeamsNavContent {
  width: calc(100% - 24%);
  overflow: auto;
  background-color: var(--gray-600);
}

.manageTeamsCard {
  & > div {
    padding: 2rem 2rem 1.5rem;
    position: relative;

    &.manageTeamsCardTop {
      background-color: var(--base-100);
      > h2 {
        width: calc(100% - 5rem);
      }
    }

    &.manageTeamsCardBody {
      border-top: 1px solid var(--gray-400);
    }
  }

  .manageTeamsCardDelete {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    font-weight: var(--weight-medium);
    color: var(--gray-300);
  }
}

.manageTeamsCardBodyContent {
  padding: 1.5rem;
  background-color: var(--base-100);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.04);
  height: 100%;
  .userMedia {
    color: inherit;
    width: 100%;
    white-space: initial;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    h6 {
      text-align: left;
    }
  }
}

.linkRing {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  border: 1px solid var(--gray-200);
  display: inline-block;

  &.blue {
    border-color: var(--secondary-500);
  }

  &.orange {
    border-color: var(--primary-100);
  }
}

.manageTeamsProgress {
  width: 10.875rem;
  height: 0.375rem;
  background-color: var(--gray-500);
  border-radius: 0.375rem;
  position: relative;
  overflow: hidden;

  span {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: var(--secondary-300);
    box-shadow: 0px 4px 8px rgba(91, 179, 129, 0.2);
  }
}

.manageTeamsAccordion {
  .accordion {
    .accordion-item {
      margin-bottom: 0.5rem;
      border: 1px solid var(--gray-400);
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.04);
      border-radius: 0.375rem;
      padding: 1rem 1.5rem;

      &:after {
        opacity: 0;
      }

      .accordion-header {
        border: 0;

        button {
          border: 0;
          color: var(--gray-100);
          font-size: 0.875rem;
          line-height: 1.25rem;
          position: relative;
          background-image: url(../images/accordionPlus.svg);
          background-repeat: no-repeat;
          background-position: center right;

          &:after {
            // right: 0;
            // position: absolute;
            opacity: 0;
          }

          &[aria-expanded="true"] {
            background-image: url(../images/accordionMin.svg);
          }
        }
      }

      .accordion-collapse {
        border: 0;
      }

      .accordion-body {
        padding: 1.5rem 0 0;

        ul {
          padding: 0;
          margin: 0;

          li {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:not(:last-child) {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
}

.manageTeamsActivityModal {
  .modal-content {
    .modal-header {
      // background-color: var(--base-100);
      // align-items: flex-start;

      .headImg {
        width: 4.625rem;
        height: 4.625rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-fit: contain;
          border-radius: 50%;
        }
      }
    }
  }
}

.businessCard {
  overflow: hidden;
  background-color: var(--base-100);

  .businessCardInner {
    border-radius: 0.375rem;
    background: url(../images/businessCardBg.png),
      linear-gradient(97.07deg, #652566 2.02%, #4a154b 104.13%);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.04);
    padding: 1.125rem;
    max-height: 200px;
    background-repeat: no-repeat;
    background-position: top right;
    color: var(--base-100);
    padding-bottom: 3.5rem;
    margin-bottom: 3.875rem;
    position: relative;
    margin: 0.75rem 0.75rem 3.875rem;
  }
}

.changePlanCard {
  padding: 0.75rem 1rem;
  position: absolute;
  left: 1.125rem;
  right: 1.125rem;
  top: 100%;
  transform: translateY(-50%);
}

.businessCardBottom {
  display: flex;
  align-items: center;
  padding: 1.25rem;
  border-top: 1px solid var(--gray-400);
  background-color: var(--gray-600);
}

.storageCard {
}

.storageCardProg {
  position: relative;
  background: var(--gray-500);
  border: 1px solid var(--gray-400);
  border-radius: 0.375rem;
  height: 0.5rem;
  margin-bottom: 2.5rem;

  span {
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: 0;
    max-width: 100%;
    background-color: var(--secondary-300);
    height: 0.5rem;
    border-radius: 0.375rem 0 0 0.375rem;

    &:after {
      content: "";
      width: 1px;
      height: 1.25rem;
      background-color: var(--secondary-300);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    h6 {
      position: absolute;
      right: 0;
      top: 120%;
      left: 0;
      text-align: right;
    }
  }
}

.paymentMethod {
  padding: 2rem 1.25rem;
}

/*change plan*/
.disc {
  background: var(--gray-500);
  border-radius: 0.3125rem;
  color: var(--primary-100);
  padding: 0.1875rem 0.3125rem;
}

.textDecoration {
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-color: var(--gray-100);
}

.planDashboard {
  .card {
    position: relative;
    @include transition;

    .posBtn {
      position: absolute;
      top: -30px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 101.6%;
      left: -2px;
      display: none;
      z-index: 9;
    }

    &.active {
      border: 2px solid var(--primary-200) !important;

      border-bottom-left-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
      margin-bottom: -20px;

      .posBtn {
        display: block;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background-image: url("../images/planFrame.png");
      background-repeat: no-repeat;
      width: 72px;
      height: 63px;
    }

    &:nth-child(1) {
      border-radius: 6px 0px 0px 6px;
    }

    &:nth-child(2) {
      border-radius: 0;
      border-right: 0;
      border-left: 0;
    }

    &:nth-child(3) {
      border-radius: 0 6px 6px 0;
    }
  }
}

.planTable {
  .posBtn {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
  }

  thead {
    border-bottom: transparent;

    th {
      width: 25%;
      border-top: 1px solid var(--gray-400);
      top: -1rem;

      &:not(:last-child) {
        border-right: 1px solid var(--gray-400);
      }

      &:not(:first-child) {
        text-align: center;
      }

      &:nth-child(3) {
        background-color: var(--pastel-100);
        // position: relative;
        border-bottom: 1px solid var(--pastel-100);
      }

      p {
        margin-bottom: 0.5rem;
        margin-top: 1.5rem;
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        td {
          p {
            &:nth-child(1) {
              white-space: nowrap;
            }

            &:nth-child(5) {
              height: 5.625rem;
            }

            &:nth-child(6) {
              height: 2.5rem;
            }
          }
        }
      }

      &:last-child {
        td {
          border-bottom: transparent !important;
        }
      }

      td {
        &:nth-child(3) {
          background-color: var(--pastel-100);
        }

        &:not(:last-child) {
          border-right: 1px solid var(--gray-400);
          border-bottom: 1px solid var(--gray-400);
        }

        &:last-child {
          border-bottom: 1px solid var(--gray-400);
        }

        &:not(:first-child) {
          text-align: center;
        }

        p {
          margin-bottom: 1.5rem;
        }

        .dash {
          position: relative;

          &:after {
            position: absolute;
            content: "";
            width: 14.67px;
            background-color: var(--gray-100);
            top: 50%;
            left: 50%;
            height: 1px;
            transform: translate(-50%, -50%);
          }
        }

        .head {
          margin-top: 1.5rem;
        }
      }
    }
  }
}

.cardType {
  background-repeat: no-repeat;
  background-position: 0.625rem 0.625rem;
  padding-left: 2.5rem !important;
}

.uPass {
  .modal-dialog {
    max-width: 40rem;
  }
  .modal-header {
    padding: 1.2rem 2.5rem;
  }
  .modal-body {
    padding: 2.5rem;
  }
  .modal-footer {
    padding: 1.2rem 2.5rem;
  }
}

.allInvoiceEmpty {
  height: calc(100% - 3.625rem);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6.375rem;
  text-align: center;
}

.paymentMethodNew {
  .smallVisaCard {
    padding: 0.875rem 1.25rem;
    border-radius: 0.375rem;
    background: var(--gray-600);
    margin-top: 1.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }
  .addressCard {
    padding: 1.25rem;
    background: var(--gray-600);
    border-radius: 0.375rem;
    margin-top: 1.25rem;
  }
}
.filterShadow-100 {
  filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.04));
}
.opacity-40 {
  opacity: 0.4 !important;
}
.trialPlan {
  &.activePlan {
    .trialPeriod {
      padding-bottom: 2rem;
    }
  }
  .trialPeriod {
    // padding-bottom: 5.75rem;
  }
  .invoiceBlock {
    height: 100%;
    width: 100%;
    // overflow: auto;
    .allInvoices {
      height: calc(100% - 3.281rem);
    }
    table {
      tr {
        td {
          text-wrap: nowrap;
        }
      }
    }
  }
}
.paymentDetailsModal {
  .modal-header {
    padding: 1.25rem 2.5rem 1.25rem 2rem;
    button {
      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }
  .modal-body {
    .leftArea {
      padding: 2.25rem;
      .planBox {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 0.75rem;
        border-bottom: 1px solid var(--gray-400);
        padding-bottom: 24px;
        .singlePlan {
          width: 18.75rem;
          height: 6.313rem;
          border: 1px solid var(--gray-400);
          padding: 0.5rem 1rem;
          border-radius: 0.375rem;
          cursor: pointer;
          &.active {
            border: 1px solid var(--secondary-300);
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='none'%3e%3cpath d='M12.5938 2.35254C7.08375 2.35254 2.59375 6.84254 2.59375 12.3525C2.59375 17.8625 7.08375 22.3525 12.5938 22.3525C18.1038 22.3525 22.5938 17.8625 22.5938 12.3525C22.5938 6.84254 18.1038 2.35254 12.5938 2.35254ZM17.3738 10.0525L11.7037 15.7225C11.5637 15.8625 11.3737 15.9425 11.1737 15.9425C10.9738 15.9425 10.7838 15.8625 10.6438 15.7225L7.81375 12.8925C7.52375 12.6025 7.52375 12.1225 7.81375 11.8325C8.10375 11.5425 8.58375 11.5425 8.87375 11.8325L11.1737 14.1325L16.3138 8.99254C16.6038 8.70254 17.0838 8.70254 17.3738 8.99254C17.6638 9.28254 17.6638 9.75254 17.3738 10.0525Z' fill='%232EB67D'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position-x: 96%;
            background-position-y: 10%;
            cursor: auto;
          }
        }
      }
    }
    .rightArea {
      border-left: 1px solid var(--gray-400);
      background: var(--gray-500);
      padding: 2.813rem 2rem 2rem 2rem;
    }
  }
}
.dottedBorderBottom {
  border-bottom: 1px dashed var(--gray-200);
  opacity: 0.75;
}
.paymentSuccessful {
  padding: 3.5rem 0;
  .subcriptionDetailsBox {
    width: 27.188rem;
    height: auto;
    border: 1px solid var(--gray-400);
  }
  .bottomBtn {
    width: 27.188rem;
    padding-bottom: 1rem;
  }
}
.paymentFailed {
  img {
    width: 6.25rem;
    height: 6.25rem;
  }
}

.planToggleSwitch {
  &.white {
    .switches-container {
      background: var(--base-100);
    }
  }
  .switches-container {
    width: 12.313rem;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: var(--gray-500);
    line-height: 2.5rem;
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
  }
  .switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
  }
  .switches-container label {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: var(--primary-200);
    font-size: 0.75rem;
    font-weight: 700;
  }
  .switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    //   padding: 0.15rem;
    z-index: 3;
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .switch {
    border-radius: 3rem;
    background: var(--primary-200);
    height: 100%;
    font-size: 0.75rem;
    font-weight: 700;
  }

  .switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: var(--base-100);
    transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
  }
  .switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
    transform: translateX(0%);
  }

  .switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
    transform: translateX(100%);
  }

  .switches-container
    input:nth-of-type(1):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(1) {
    opacity: 1;
  }
  .switches-container
    input:nth-of-type(2):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(2) {
    opacity: 1;
  }
}
.selectPlan {
  margin-top: 2rem;
  .planBox {
    padding: 1.75rem 2rem;
    background: rgba(217, 217, 217, 0.2);
    &.current {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='none'%3e%3cpath d='M12.7217 2.72559C7.21168 2.72559 2.72168 7.21559 2.72168 12.7256C2.72168 18.2356 7.21168 22.7256 12.7217 22.7256C18.2317 22.7256 22.7217 18.2356 22.7217 12.7256C22.7217 7.21559 18.2317 2.72559 12.7217 2.72559ZM17.5017 10.4256L11.8317 16.0956C11.6917 16.2356 11.5017 16.3156 11.3017 16.3156C11.1017 16.3156 10.9117 16.2356 10.7717 16.0956L7.94168 13.2656C7.65168 12.9756 7.65168 12.4956 7.94168 12.2056C8.23168 11.9156 8.71168 11.9156 9.00168 12.2056L11.3017 14.5056L16.4417 9.36559C16.7317 9.07559 17.2117 9.07559 17.5017 9.36559C17.7917 9.65559 17.7917 10.1256 17.5017 10.4256Z' fill='%232EB67D'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 3%;
      .current-btn {
        background: rgba(88, 29, 89, 0.1);
        border: none;
        cursor: auto;
        :hover {
          background: rgba(88, 29, 89, 0.1);
          color: var(--primary-200);
        }
      }
    }
  }
}

.manageAddons {
  .singleBox {
    width: 24.375rem;
    height: auto;
    padding: 1.25rem;
    border: 0.063rem solid var(--gray-400);
    border-radius: 0.375rem;
  }
  .billingPrice {
    width: 24.375rem;
  }
}
.manageBtn {
  border: 0.063rem solid rgba(136, 136, 136, 0.3);
  display: inline-block;
  &:hover {
    border: 0.063rem solid var(--primary-200);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  }
}

.changePlanModal {
  height: 100%;
  width: 100%;
  background: var(--gray-500);
}
.text-28 {
  font-size: 1.75rem;
}
.changePlanModal {
  p {
    margin-bottom: 0;
  }
  overflow: auto;
  .selectArea {
    margin-top: 2.5rem;
    .single-box {
      background: var(--pastel-900);
      padding: 3rem 2rem 1rem 2rem;
      border: 0.063rem solid var(--gray-500);
      transition: all 0.4s;
      &:hover {
        background: var(--base-100);
        box-shadow: 0.75rem 0.688rem 2rem 0px rgba(0, 0, 0, 0.1);
      }
      h6 {
        color: var(--gray-1200);
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &.lineExtra {
          height: 2.6rem;
        }
      }
    }
    .leftSide {
      padding: 3rem 2rem 1rem 2rem;
      h6 {
        color: var(--gray-1200);
        border-bottom: 0.063rem dashed var(--gray-1200);
        width: fit-content;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &.lineExtra {
          height: 2.6rem;
          border: none;
          text-decoration: underline dashed;
          text-underline-position: under;
        }
      }
    }
  }
}
.btn-dark {
  background: rgba(41, 45, 50, 0.2) !important;
  border: 0.063rem solid var(--base-100) !important;
  :hover {
    background: rgba(41, 45, 50, 0.3) !important;
    box-shadow: 0px 0.5rem 1rem rgba(74, 21, 75, 0.12) !important;
    border: 0.063rem solid var(--base-100) !important;
  }
}

.billingDropdown {
  .dropdown-toggle {
    padding: 0;
    background: none !important;
    box-shadow: none !important;
    border: 0;
    padding: 0.125rem 0;
    border-radius: 0.25rem;
    &.show{
      background: rgba(217, 217, 217, 0.30) !important;
    }
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }
  .dropdown-menu {
    box-shadow: 0 0.375rem 0.5rem rgba(0, 0, 0, 0.04);
    border: 0.0625rem solid var(--gray-500);
    padding: 0;
    // border-radius: 0.25rem;
    z-index: 1;

    .dropdown-item {
      font-size: 0.875rem;
      line-height: 1.125rem;
      padding: 1rem 2rem;
      font-weight: 700;
      &:last-child{
        color: red;
      }
      @include transition;
      color: var(--gray-100);

      &:hover,
      &.active {
        background-color: var(--gray-600);
        // font-weight: var(--weight-medium);
      }

      &.active {
        font-weight: var(--weight-medium);
      }
    }
  }
}

.w-120{
  width: 7.5rem;
}
.w-96{
  width: 6.25rem;
}

.planComparisonModal{
  .singlePlanBox{
    background: var(--pastel-900);
    border: 0.063rem solid var(--gray-900);
    border-radius: 0.375rem;
    padding: 1.5rem;
    &.left{
      border: 0.063rem solid transparent;
      border-radius: 0;
      background: transparent;
      border: none;
      h6{
        justify-content: start;
        position: relative;
        width: fit-content;
        border-bottom: 0.063rem dashed var(--gray-1200);
        color: var(--gray-100);
      }
    }
    &.first{
      border-right: 0;
      border-radius: 0.375rem 0 0 0.375rem;
    }
    &.second{
      border-radius: 0;
      border-right: 0;
    }
    &.three{
      border-radius: 0 0.375rem 0.375rem 0;
    }
    h6{
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #292D32;
      &.lineExtra{
        height: 2.5rem !important;
      }
      &.condition{
        position: relative;
        .star{
          position: absolute;
          top: -0.25rem;
          left: 54%;
          color: var(--primary-100);
        }
      }
    }
  }
}
.h-40{
  height: 2.5rem;
}
.h-60{
  height: 3.75rem;
}