.checkList {
    padding: 0;
    margin: 0;
    li {
        padding: 0;
        margin: 0;
        list-style: none;
        background-image: url(../images/checkList.svg);
        background-repeat: no-repeat;
        background-position: left 0.2rem;
        padding-left: 1.875rem;
        &:not(:last-child) {
            margin-bottom: 0.75rem;
        }
    }
}
.checkList1{
    padding: 0;
    margin: 0;
    li {
        padding: 0;
        margin: 0;
        list-style: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M9.97331 1.7334C5.61122 1.7334 2.05664 5.28798 2.05664 9.65006C2.05664 14.0121 5.61122 17.5667 9.97331 17.5667C14.3354 17.5667 17.89 14.0121 17.89 9.65006C17.89 5.28798 14.3354 1.7334 9.97331 1.7334ZM13.7575 7.82923L9.26872 12.318C9.15789 12.4288 9.00747 12.4921 8.84914 12.4921C8.69081 12.4921 8.54039 12.4288 8.42956 12.318L6.18914 10.0776C5.95956 9.84798 5.95956 9.46798 6.18914 9.2384C6.41872 9.00881 6.79872 9.00881 7.02831 9.2384L8.84914 11.0592L12.9183 6.99007C13.1479 6.76048 13.5279 6.76048 13.7575 6.99007C13.9871 7.21965 13.9871 7.59173 13.7575 7.82923Z' fill='%232EB67D'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: left 0.05rem;
        padding-left: 1.875rem;
        &:not(:last-child) {
            margin-bottom: 0.75rem;
        }
    }
}