.responseList{
    ul{
        li{
            padding: .75rem 1.25rem;
            margin-top: .75rem;
            position: relative;
        }
        &.progressBar{
            li{
                h5, span{
                    position: relative;
                    z-index: 1;
                }
                &.p10{
                    &:after{
                        content: "";
                        position: absolute;
                        background: var(--gray-500);
                        width: 10%;
                        left: 0;
                        height: 100%;
                        z-index: 0;
                    }
                }
                &.p20{
                    &:after{
                        content: "";
                        position: absolute;
                        background: var(--gray-500);
                        width: 20%;
                        left: 0;
                        height: 100%;
                        z-index: 0;
                    }
                }
                &.p30{
                    &:after{
                        content: "";
                        position: absolute;
                        background: var(--gray-500);
                        width:30%;
                        left: 0;
                        height: 100%;
                        z-index: 0;
                    }
                }
                &.p40{
                    &:after{
                        content: "";
                        position: absolute;
                        background: var(--gray-500);
                        width: 40%;
                        left: 0;
                        height: 100%;
                        z-index: 0;
                    }
                }
                &.p50{
                    &:after{
                        content: "";
                        position: absolute;
                        background: var(--gray-500);
                        width: 50%;
                        left: 0;
                        height: 100%;
                        z-index: 0;
                    }
                }
                &.p60{
                    &:after{
                        content: "";
                        position: absolute;
                        background: var(--gray-500);
                        width: 60%;
                        left: 0;
                        height: 100%;
                        z-index: 0;
                    }
                }
                &.p70{
                    &:after{
                        content: "";
                        position: absolute;
                        background: var(--gray-500);
                        width: 70%;
                        left: 0;
                        height: 100%;
                        z-index: 0;
                    }
                }
                &.p80{
                    &:after{
                        content: "";
                        position: absolute;
                        background: var(--gray-500);
                        width: 80%;
                        left: 0;
                        height: 100%;
                        z-index: 0;
                    }
                }
                &.p90{
                    &:after{
                        content: "";
                        position: absolute;
                        background: var(--gray-500);
                        width:90%;
                        left: 0;
                        height: 100%;
                        z-index: 0;
                    }
                }
                &.p100{
                    &:after{
                        content: "";
                        position: absolute;
                        background: var(--gray-500);
                        width: 100%;
                        left: 0;
                        height: 100%;
                        z-index: 0;
                    }
                }
            }
        }
    }
}

.cardCount{
    span{
        width: 3rem;
        height: 2.75rem;
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}

.idividualList{
    li{
        margin-bottom: 2rem;
    }
}
.formQus{
    position: relative;
    .count{
        position: absolute;
        left: -14px;
        font-size: 1rem;
    }
}
.pageCount{
    display: flex;
    justify-content: center;
    align-items: center;
    span{
        padding: 0px .2rem;
        margin: 0px .3rem;
        font-weight: bold;
        border-bottom: 1px solid #424242
    }
    .pBtn{
        padding: 0;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.375rem;
        border: 1px solid #D9D9D9;
        &:hover{
            background-color: #000;
            border-color: #000;
            svg{
                path{
                    fill: #fff;
                }
            }
        }
    }
}

ul.paginationModal{
    text-align: right;
    li{
        display: inline-block;
        &.border-right{ 
            .pBtn {
                border-right: 1px solid #d9d9d9;
                border-radius: 0px;
                padding: 0px;
                padding-right: 1.3rem;
            }
        }
    }
}
.form-switch{
    label {
        font-size: .875rem;
    }
}

.labelFor {
    > label {
        > span {
            padding: 0px;
        }
    }
}
.newSeqAss{
    .journyCardshadow{
        box-shadow: none;
        border: none;
        margin-bottom: 2rem;
        padding-top: 2rem;
        margin-right: 1rem;
        border-top: 1px solid #F2F2F5;
        border-radius: 0px;
    }
    .col-lg-4:nth-child(1), .col-lg-4:nth-child(2), .col-lg-4:nth-child(3){
        .journyCardshadow{
            border: none;
        }
    }
}
.w18rem {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 18rem;
}
.w20rem {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // max-width: 18rem;
}
span.w10rem {
    max-width: 13rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}