.radioButton {
    display: inline-block;
    margin: 0;
    padding-left: 1.875rem;
    position: relative;
    .radioCheck {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        border: 1px solid var(--gray-400);
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0.125rem;
        background-color: #fff;
    }
    input[type="radio"] {
        display: none;
        &:checked ~ .radioCheck {
            border: 5px solid var(--primary-200);
        }
    }
}

.h-50center {
    height: 3rem;
    display: flex;
}