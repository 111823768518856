.employeeAccountImg {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.learningResources {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.learningCard {
    margin-bottom: 1.5rem;
    box-shadow: 0px 0px 20px rgba(40, 43, 45, 0.05);

    .learningCardImg {
        img {
            width: 100%;
            display: block;
            border-radius: $radius $radius 0 0;
        }
    }

    .learningCardText {
        position: relative;
        background-color: var(--base-100);
        border: 1px solid var(--gray-400);
        padding: 1.6875rem 1rem 1.5rem;
        border-top: 0;
        border-radius: 0 0 $radius $radius;
    }

    .learningCardBadge {
        width: 2.5rem;
        height: 2.5rem;
        background-color: var(--base-100);
        border-radius: 50%;
        position: absolute;
        top: 0;
        margin-top: -1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            position: absolute;
            font-weight: var(--weight-bold);
        }
    }
}

.badgeScoreCard {
    // padding: 1.25rem;
    border-radius: $radius;
    border: 1px solid var(--gray-400);
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(40, 43, 45, 0.05);

    // margin-top: 1.5rem;
    .badgeScoreCardTop {
        padding: 1.25rem;
        background-color: var(--base-100);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .badgeScoreCardBadge {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
            position: absolute;
        }
    }

    .badgeScoreCardBottom {
        border-top: 1px solid var(--gray-400);
        padding: 2rem 1.25rem;
        background-color: var(--gray-600);
        display: flex;
        justify-content: space-between;

        &>div {
            &:not(:first-child) {
                padding-left: 1rem;
                border-left: 1px solid var(--gray-400);
            }

            span {
                max-width: 4.5rem;
                display: block;
            }
        }
    }
}

.topLearnersCard {
    padding: 1.25rem;
    border-radius: $radius;
    border: 1px solid var(--gray-400);
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(40, 43, 45, 0.05);
    margin-top: 1.5rem;
    background-color: var(--base-100);
}

.topLearnersCardList {
    &>div {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        justify-content: space-between;

        &:not(:last-child) {
            border-bottom: 1px solid var(--gray-400);
        }
    }
}

.topLearnersCardListTag {
    background-color: var(--gray-500);
    padding: 0.375rem 0.75rem;
    border-radius: 5rem;
}


/*employee account*/