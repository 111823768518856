$fontSize: 16px;
$primaryFont: 'Inter', sans-serif;
// $secondFont: "Poppins", sans-serif;
$continerPadding: 2.75rem;
$colors: (
    base: (100: #fff,
        200: #000,
        300:#ffffffcc,

    ),
    primary: (100: #F15845,
        200: #4A154B,
        300: #320B33,
        400: #D74B3A,

    ),
    secondary: (100: #36C5F0,
        200: #ECB22E,
        300: #2EB67D,
        400: #E3828D,
        500: #0080FF,
        600: #E01E1E,
        700:#CCABCD,
        800:#9269F7,
        900: #AB7700,
    ),
    gray: (100: #232323,
        200: #646464,
        300: #888888,
        400: #D9D9D9,
        500: #EFEFEF,
        600: #F8F8F8,
        700:#744D75,
        800:#2B2F32,
        900:#DCDCDC,
        1000:#646464cc,
        1100:#fbfbfb,
        1200:#3c3c3c,
        1300:#757575,
        1400:#6D6BBF,
    ),
    pastel: (100: #F6EFE8,
        200: #F3D1CB,
        300: #F5F3DD,
        400: #F0E9FF,
        500: #E9F3E4,
        600: #E9EFF5,
        700: #E2F5FF,
        800: #F4F4F4,
        900: #F9F9F9,
        1000:#5F5F5F,
    ),

    gradient:(100:linear-gradient(97.07deg, #652566 2.02%, #4A154B 104.13%),
    ),
);

$fontsWeight: (
    extraBold:800,
    bold:700,
    medium:500,
    normal:400,
);

$radius: 0.375rem;

$base-100: #ffff;
$gray-300: #888888;
$secondary-800: #9269F7;