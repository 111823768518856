.formTypeCom {

    .myInput,
    .mySelect {
        max-width: calc(100% - 7rem);
    }
}

.formTypeComMultipleChoice {
    .iconGroupRadio {
        display: flex;
        align-items: center;

        svg+svg,
        button+button {
            margin-left: 1rem;
        }
    }
}